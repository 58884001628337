import {
  LocationQuery,
  LocationQueryEnum,
  RegionType,
} from "@b2bportal/air-shopping-api";
import {
  FlightSearchQueryParamValues,
  IdEnum,
  ITripTerminus,
} from "@b2bportal/core-types";
import { logger, suggestionToITripTerminus } from "@b2bportal/core-utilities";
import { fetchLocationAutocomplete } from "@hopper-b2b/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildFlightShopValuesFromQueryParams } from "../../hooks/shopQueryParams";
import { FlightSearchValues } from "../slice";

export const initializeSearchState = createAsyncThunk<
  FlightSearchValues,
  Partial<FlightSearchQueryParamValues>
>("flightsShop/initializeSearchState", async (urlParameters, thunkAPI) => {
  const { origin: originCode, destination: destinationCode } = urlParameters;

  const shopValues = buildFlightShopValuesFromQueryParams(urlParameters);

  try {
    if (originCode == null || destinationCode == null) {
      throw new Error("Origin or destination is not set");
    }
    const [origin, destination] = await Promise.all([
      fetchLocation(originCode),
      fetchLocation(destinationCode),
    ]);
    const newSearchState: FlightSearchValues = {
      ...shopValues,
      origin: origin ?? locationCodeToITripTerminus(originCode),
      destination: destination ?? locationCodeToITripTerminus(destinationCode),
    };
    return newSearchState;
  } catch (error) {
    logger.debug(`Error fetching search locations. ${error}`);
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});

const fetchLocation = async (
  locationCode: string
): Promise<ITripTerminus | undefined> => {
  const requestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: locationCode,
  };
  const { categories } = await fetchLocationAutocomplete(requestBody);

  const suggestion = categories
    .flatMap((category) => category.results)
    .find(
      (result) =>
        result.id.Id === IdEnum.Flight && result.id.code.code === locationCode
    );

  return suggestion == null ? undefined : suggestionToITripTerminus(suggestion);
};

const locationCodeToITripTerminus = (code: string): ITripTerminus => {
  return {
    id: {
      code: {
        regionType: RegionType.airport,
        code,
      },
      Id: IdEnum.Flight,
    },
    label: code,
    trackingProperties: {},
  };
};
