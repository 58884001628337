import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import { CarReservation } from "./groundItinerary";

export const TRIPS_FILTER_QUERY_PARAM = "tripsFilter";

export enum MyTripsFilter {
  UPCOMING_TRIPS = "upcoming_trips",
  WATCHED_TRIPS = "watched_trips",
  PRIZE_FREEZES = "frozen_prices",
  PAST_TRIPS = "past_trips",
  FLIGHTS = "flights",
  HOTELS = "hotels",
}

export enum ItineraryEnum {
  Flight = "Flight",
  Hotel = "Hotel",
  Watch = "Watch",
  Car = "Car",
  AirPriceFreeze = "AirPriceFreeze",
}

export type Itinerary =
  | BookedFlightItineraryWithDepartureTime
  | HotelItinerary
  | HotelItinerarySummary
  | CarReservation
  | WatchAlertView;

export interface Flight extends BookedFlightItineraryWithDepartureTime {
  type: ItineraryEnum.Flight;
}

export interface Hotel extends HotelItinerarySummary {
  type: ItineraryEnum.Hotel;
}

export interface Car extends CarReservation {
  type: ItineraryEnum.Car;
}

export interface Watch extends WatchAlertView {
  type: ItineraryEnum.Watch;
}

export interface HotelSummary extends HotelItinerarySummary {
  type: ItineraryEnum.Hotel;
}

export interface HotelItineraryWithType extends HotelItinerary {
  type: ItineraryEnum.Hotel;
}

export type ItineraryWithType = Flight | Watch | Car | HotelItineraryWithType;

export type ItinerarySummaryWithType = Flight | HotelSummary | Watch | Car;

export enum MyTripsModalTypes {
  ConfirmationModal = "ConfirmationModal",
  ExchangeFlight = "ExchangeFlight",
  PaymentModal = "PaymentModal",
  ResendConfirmation = "ResendConfirmation",
  SelfServeCancelFlight = "SelfServeCancelFlight",
  TravelersModal = "TravelersModal",
  OutboundItinerary = "OutboundItinerary",
  ReturnItinerary = "ReturnItinerary",
  ScheduleChange = "ScheduleChange",
  StopWatching = "StopWatching",
  ChangeEmail = "ChangeEmail",
  /**
   * @deprecated Old Flight Cancel design that tells users to contact support.
   * Use this while the Self Serve Cancel Flight feature isn't supported in the tenant's BFF
   */
  CancelFlight = "CancelFlight",
  CfarFlight = "CfarFlight",
  CancelHotel = "CancelHotel",
  ChangeHotel = "ChangeHotel",
  CheckInFlight = "CheckInFlight",
  CarConfirmation = "CarConfirmation",
  DriversModal = "DriversModal",
  ChangeCar = "ChangeCar",
  CancelCar = "CancelCar",
  PickUp = "PickUp",
  DropOff = "DropOff",
  BasicInfo = "BasicInfo",
  ContactSupport = "ContactSupport",
  MissedConnection = "MissedConnection",
  DelayDisruption = "DelayDisruption",
  DisruptionExerciseInfo = "DisruptionExerciseInfo",
}

export interface IOpenModal {
  type: MyTripsModalTypes | null;
  selectedItinerary: ItineraryWithType | null;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
  redirectUrl: string;
}

export interface FlightInfoDetails {
  origin: string;
  destination: string;
  confirmationCode: string;
  startDate: string;
  endDate: string;
  roundTrip: boolean;
  airlineCode: string;
  airlineName: string;
  flightNumber: string;
}
