import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { useSelector } from "react-redux";
import { IconNameEnum, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionOfferPage.module.scss";
import clsx from "clsx";
import {
  DisruptionOfferSection,
  DisruptionOffersSelectors,
} from "@b2bportal/core-disruption";
import {
  DisruptionOfferQuoteSelectionEnum,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { ClientName } from "@hopper-b2b/types";
import {
  FintechProductInfoLinksLayout,
  FintechProductInfoLinksPlacement,
} from "@b2bportal/core-fintech-ui";
import { Dialog, DialogTrigger, IframeDialogContent } from "@b2bportal/core-ui";

interface DisruptionOfferPageProps extends PlatformComponentProps {
  goToNextStep?: (replace?: boolean) => void;
}

// Standalone disruption offer screen based on core-disruption module. Used in mobile web.
export const DisruptionOfferPage = ({
  className,
  goToNextStep,
}: DisruptionOfferPageProps) => {
  const COMPONENT_KEY = "DisruptionOfferPage";
  const [block, cn] = useModuleBEM(defaultStyles, COMPONENT_KEY);

  const { t, formatFiatCurrency } = useI18nContext();

  const disruptionOffer = useSelector(
    DisruptionOffersSelectors.getDisruptionOfferToShow
  );

  const selectedDisruptionOffer = useSelector(
    DisruptionOffersSelectors.getSelectedDisruptionOffer
  );

  if (disruptionOffer == null) {
    return null;
  }

  const bulletLinePrefixIcon =
    getEnvVariables("clientName") === ClientName.NUBANK
      ? {
          iconName: IconNameEnum.checkCircle,
          className: cn("check-icon"),
        }
      : undefined;

  const howItWorksDialogBulletLinePrefixIconName =
    getEnvVariables("clientName") === ClientName.NUBANK
      ? IconNameEnum.checkCircle
      : undefined;

  const optionsSectionUI =
    getEnvVariables("clientName") === ClientName.NUBANK
      ? {
          showTitle: true,
        }
      : undefined;

  const infoLinksUI =
    getEnvVariables("clientName") === ClientName.NUBANK
      ? {
          placement: FintechProductInfoLinksPlacement.BelowOffers,
          layout: FintechProductInfoLinksLayout.Vertical,
          suffixIcon: {
            iconName: IconNameEnum.rightArrow,
            className: cn("right-arrow-icon"),
          },
        }
      : undefined;

  const continueButtonText = () => {
    if (
      selectedDisruptionOffer?.selection ===
      DisruptionOfferQuoteSelectionEnum.Selected
    ) {
      return t("core-disruption.offerCTA.continueWithSelectedOffer");
    } else {
      return t("core-disruption.offerCTA.continueWithoutSelection");
    }
  };

  const secondaryContent =
    selectedDisruptionOffer != null ? (
      <div className={cn("secondary-content")}>
        <button
          className={cn("continue-button")}
          onClick={() => {
            goToNextStep();
          }}
        >
          {continueButtonText()}
        </button>
      </div>
    ) : (
      <></>
    );

  return (
    <div className={clsx(block, className)}>
      <div
        className={cn("container", {
          "show-secondary-content": selectedDisruptionOffer != null,
        })}
      >
        <DisruptionOfferSection
          onInvalidOffer={goToNextStep}
          disruptionOffer={disruptionOffer}
          optionsSectionUI={optionsSectionUI}
          infoLinksUI={infoLinksUI}
          optional={
            getEnvVariables("clientName") === ClientName.NUBANK && {
              centerBulletIcons: true,
              extraBodyLines: [
                {
                  lineType: "disclaimer",
                  tKey: "core-disruption.disclaimer",
                  linkUrl:
                    "https://hts.hopper.com/legal/hts-passenger-rights-overview",
                  components: [
                    <DialogTrigger
                      className={cn("benefits-from-airline-link")}
                    ></DialogTrigger>,
                  ],
                },
              ],
            }
          }
          bulletLinePrefixIcon={bulletLinePrefixIcon}
          howItWorksDialogBulletLinePrefixIconName={
            howItWorksDialogBulletLinePrefixIconName
          }
          radioButtonPosition="start"
          displayExternalLinksAsModal={true}
        />

        {secondaryContent}
      </div>
    </div>
  );
};
