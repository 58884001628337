import { FiatPrice, TrackingProperties } from "@b2bportal/air-shopping-api";
import { IIdFlight, Id } from "../common/search";
import { FareclassShelfBrandName } from "./shop";

export enum FlightSearchQueryParams {
  ORIGIN = "origin",
  DESTINATION = "destination",
  DEPARTURE_DATE = "departureDate",
  RETURN_DATE = "returnDate",
  TRIP_CATEGORY = "tripCategory",
  STOP_OPTIONS = "stopOptions",
  ADULT_COUNT = "adultCount",
  CHILD_COUNT = "childCount",
  INFANT_IN_SEAT_COUNT = "infantInSeatCount",
  INFANT_IN_LAP_COUNT = "infantOnLapCount",
  NO_LCC = "noLCC",
}

export type FlightSearchQueryParamValues = {
  [key in FlightSearchQueryParams]: string;
};

export enum TripCategory {
  ONE_WAY = "one_way",
  ROUND_TRIP = "round_trip",
}

export interface IAirlineOptions {
  label: string;
  value: string;
}

export interface IAirportOptions {
  label: string;
  value: string;
}

export interface IFlightNumbersByAirlineCode {
  [key: string]: string[];
}

export interface FlightNumberOption {
  value: {
    airlineCode: string;
    flightNumber: string;
  };
  label: string;
}

export interface ISliceTimeRange {
  departureTimeRange: ITimeRange;
  arrivalTimeRange: ITimeRange;
}

export interface IFilterOptions {
  airlineOptions: IAirlineOptions[];
  airportOptions: IAirportOptions[];
  flightNumberOptions: FlightNumberOption[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  priceMin?: FiatPrice;
  priceMax?: FiatPrice;
}

export enum FareClassEnum {
  basic = "basic",
  standard = "standard",
  enhanced = "enhanced",
  premium = "premium",
  luxury = "luxury",
}

export type FareClassOptionFilter = {
  [key in FareClassEnum]: boolean;
};

export interface IAirlineOptions {
  label: string;
  value: string;
}

export interface IDateBucket {
  legend: string;
  dates: string[];
}

export interface IMonthBucket {
  monthIndex: number;
  dates: IBucketedDate[];
}

export type SerializableMonthBucket = {
  monthIndex: number;
  dates: {
    date: string;
    bucket: number;
  }[];
};

export interface IBucketedDate {
  date: Date;
  bucket: number;
}

export interface IResult {
  id: Id;
  label: string;
  trackingProperties: TrackingProperties;
  subLabel?: string;
}

export type ITripTerminus = IResult & {
  id: IIdFlight;
};

export interface IPassengerCounts {
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
}

export enum SliceStopCountFilter {
  DEFAULT = "DEFAULT",
  ANY_NUMBER = "ANY_NUMBER",
  NONE = "NONE",
  ONE_OR_LESS = "ONE_OR_LESS",
  TWO_OR_LESS = "TWO_OR_LESS",
}

export type FareclassOptionFilter = {
  [key in FareclassShelfBrandName]: boolean;
};

export interface ITimeRange {
  // note: time is measured in minutes
  min: number;
  max: number;
}

export type AirlineCode = string;

export interface IFlightNumberFilter {
  airlineCode: AirlineCode;
  flightNumber: string;
}

export enum TripFilter {
  NO_FILTER = "NoFilter",
  AND_FILTER = "AndFilter",
  NON_STOP_FILTER = "NonStopFilter",
  SHORT_LAYOVER_FILTER = "ShortLayoverFilter",
  NO_LOW_COST_CARRIER_FILTER = "NoLowCostCarrierFilter",
}

export enum SearchEvents {
  VIEW_CALENDAR = "VIEW_CALENDAR",
  SEARCH_TRAVELERS_UPDATED = "SEARCH_TRAVELERS_UPDATED",
}

export enum MobileFlightSearchDialogSteps {
  MainSearch = "MainSearch",
  Calendar = "Calendar",
}

export type FlightPricePrediction = {
  departureMonths: IMonthBucket[];
  priceTags: string[];
  origin?: ITripTerminus;
  destination?: ITripTerminus;
  tripCategory?: TripCategory;
  isLoading: boolean;
};
