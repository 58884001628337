import { Divider, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  BookedFlightItineraryWithDepartureTime,
  MultiTicketType,
  Status,
} from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { UberExchangeFlightModalContent } from "@hopper-b2b/self-serve";
import {
  ChfarExerciseProgress,
  ClientName,
  FlightItineraryState,
  FlightSummaryInfo,
  FlightSummaryInfoWithKeys,
  getFlightInfoDetails,
  getFlightSummaryInfo,
  getReturnSlice,
  IPerson,
  MyTripsFilter,
  MyTripsModalTypes,
  ScheduleChangeSeverity,
  TagColors,
  TagInfo,
  VIEWED_TRIP_CARD,
} from "@hopper-b2b/types";
import {
  ActionLinkKey,
  AirlineIcon,
  IActionLink,
  IconComponent,
  IconName,
  MobileFlightSummaryRowNew,
  PassengerSummaryRow,
  Slot,
  StatusTag,
  UberPriceSummaryRow,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  toggleAdaChat,
  useEnableAirExchange,
  useEnableCoreDisruptionProtection,
  useUberBridge,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../../../App";
import { PATH_HOME } from "../../../../../../utils/paths";
import {
  fetchFlightCfarCancellationQuote,
  populateTripQueryParams,
  setChfarExerciseProgress,
} from "../../../../../TripsList/actions/actions";
import { addFlightType } from "../../../../../TripsList/components/ItineraryList/components/FlightCard/helpers";
import { getConfirmationNumbers } from "../../../../../TripsList/components/ItineraryList/components/ItinerariesModal/components/ConfirmationModalContent/component";
import { MobileActionLinks } from "../../../../../TripsList/components/ItineraryList/components/MobileActionLinks";
import { StatusBanner } from "../../../../../TripsList/components/ItineraryList/components/StatusBanner";
import { DATE_FORMAT } from "../../constants";
import { MobileFlightItineraryDetailsConnectorProps } from "./container";

import "./styles.scss";
import { ChatPropertiesType } from "@b2bportal/chat-api";
import {
  DisruptionAlertBannerType,
  DisruptionContractStatus,
  DisruptionExerciseProgress,
  FlightDisruptionAlertBanner,
  setDisruptionExerciseProgress,
} from "@b2bportal/core-disruption";
import { useExperiment } from "@hopper-b2b/experiments";

enum ISelfServePage {
  cancel = "cancel",
  exchange = "exchange",
}

interface IMobileFlightItineraryDetailsProps
  extends RouteComponentProps,
    MobileFlightItineraryDetailsConnectorProps {
  onSupportClick?: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

export const MobileFlightItineraryDetails = ({
  flight,
  airportMap,
  airlineMap,
  fetchFlights,
  setSelectedFlight,
  history,
  setOpenModal,
  tripsFilter,
  onSupportClick,
}: IMobileFlightItineraryDetailsProps) => {
  const clientContext = useContext(ClientContext);
  const { t, formatFiatCurrency, brand } = useI18nContext();
  const { setHeader } = useUberBridge();
  const { status, travelCredit, travelCreditCopy, ancillaries } = flight ?? {};
  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);
  const isCancelled = status === Status.Canceled;
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;
  const tripHasAirChar = ancillaries?.cfar?.isExercisable;
  const tripHasAirChfar = ancillaries?.chfar?.isExercisable;
  const showAirCfar = tripHasAirChar;
  const showAirChfar = tripHasAirChfar;
  const dispatch = useDispatch();
  const enableAirExchange = useEnableAirExchange();
  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;
  const [selfServePage, setSelfServePage] = useState<ISelfServePage | null>(
    null
  );
  const hasFTC = !!travelCredit && !!travelCreditCopy;
  const enableUberDisruption = useExperiment("uber-disruption");
  const showDisruption =
    useEnableCoreDisruptionProtection() || enableUberDisruption;
  const { disruptionProtection } = flight.ancillaries;
  const hasDisruptionProtection = !!disruptionProtection && showDisruption;
  const isDisruptionProtectionExercisable =
    hasDisruptionProtection &&
    disruptionProtection.status.DisruptionContractStatus ===
      DisruptionContractStatus.ANY_EXERCISE_ELIGIBLE;
  const isEligibleToBookReturn =
    hasDisruptionProtection &&
    disruptionProtection.status.DisruptionContractStatus ===
      DisruptionContractStatus.REBOOK_ONLY_ELIGIBLE;

  const goBack = useCallback(
    (didCancel?: boolean) => {
      if (selfServePage) {
        setHeader({
          title: t("tripSummary"),
        });
        setSelfServePage(null);
        if (didCancel) {
          // we want to keep this call in sync with the fetchFlights call in UberTripsList/component.tsx
          // please cross reference any changes with that function
          fetchFlights(
            {
              states: [
                FlightItineraryState.Canceled,
                FlightItineraryState.Future,
                FlightItineraryState.Present,
                FlightItineraryState.Past,
              ],
              referenceDateTime: dayjs().format(),
            },
            history
          );
        }
      } else {
        setSelectedFlight(null);
        history.push(PATH_HOME);
      }
    },
    [fetchFlights, history, selfServePage, setHeader, setSelectedFlight, t]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({ eventName: VIEWED_TRIP_CARD, properties: undefined });
  }, []);

  useEffect(() => {
    setHeader({
      title: t("tripSummary"),
    });
  }, [setHeader, t]);

  const confirmationNumbers = useMemo(() => {
    if (flight) {
      const allConfirmationNumbers = getConfirmationNumbers({
        flight,
        airlineMap,
      });
      return allConfirmationNumbers.filter(
        (confirmationNumber, index, arr) =>
          index ===
          arr.findIndex((item) => item.locator === confirmationNumber.locator)
      );
    }
  }, [flight, airlineMap]);

  const isMultiAirline = useMemo(
    () =>
      flight.bookedItinerary.multiTicketType === MultiTicketType.hackerFare ||
      flight.bookedItinerary.multiTicketType ===
        MultiTicketType.virtualInterline,
    [flight]
  );

  const closeFTCPopover = () => {
    setFTCPopoverOpen(false);
  };

  const onOpenModal = (type: MyTripsModalTypes) => {
    switch (type) {
      case MyTripsModalTypes.ContactSupport:
        if (onSupportClick) {
          onSupportClick(
            flight.bookedItinerary.id,
            ChatPropertiesType.Air,
            "General"
          );
        } else {
          toggleAdaChat();
        }
        break;
      case MyTripsModalTypes.ScheduleChange:
        toggleAdaChat();
        break;
      case MyTripsModalTypes.ExchangeFlight:
        setSelfServePage(ISelfServePage.exchange);
        break;
      default:
        setOpenModal({
          type,
          selectedItinerary: addFlightType(flight),
        });
        break;
    }
  };

  const mapTitleKeys = ({
    titleKeys,
    ...summary
  }: FlightSummaryInfoWithKeys): FlightSummaryInfo => {
    const title = t("originToDestination", titleKeys);
    return {
      title,
      ...summary,
    };
  };

  // TODO: time validation should be done on BE
  const isWithin24HoursUntilDeparture = (
    flight: BookedFlightItineraryWithDepartureTime
  ) => {
    const timeDiff = dayjs(flight.departureTime).diff(dayjs(), "h");
    return timeDiff < 24;
  };

  const actions = useMemo(() => {
    const actions: IActionLink[] = [];

    if (!isPastTrips && isWithin24HoursUntilDeparture(flight)) {
      actions.unshift({
        content: t("tripReviewLinks.checkInForFlight"),
        onClick: () => onOpenModal(MyTripsModalTypes.CheckInFlight),
        key: ActionLinkKey.CHECK_IN,
      });
    }

    if (!isCancelled && !isPastTrips) {
      if (!showAirCfar) {
        actions.push({
          content: t("tripReviewLinks.cancelFlight"),
          onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
          key: ActionLinkKey.CANCELLATION,
        });
      }

      if (showAirCfar) {
        const contractId = ancillaries.cfar.id.policyId;
        const itineraryId = flight.bookedItinerary.id;
        actions.push({
          linkClassName: "mobile-cfar-link",
          content: <>{t("cancelForAnyReason")}</>,
          onClick: () => {
            dispatch(
              fetchFlightCfarCancellationQuote({
                contractId,
                itineraryId,
              })
            );
            dispatch(populateTripQueryParams(history));
          },
          key: ActionLinkKey.CFAR,
        });
      }

      if (showAirChfar) {
        actions.push({
          content: <>{t("changeForAnyReason")}</>,
          onClick: () => {
            dispatch(setChfarExerciseProgress(ChfarExerciseProgress.Started));
            dispatch(populateTripQueryParams(history));
          },
          key: ActionLinkKey.CHFAR,
        });
      }

      if (enableAirExchange && !showAirChfar) {
        actions.push({
          content: t("tripReviewLinks.changeFlight"),
          onClick: () => onOpenModal(MyTripsModalTypes.ExchangeFlight),
          key: ActionLinkKey.EXCHANGE,
        });
      }
    }

    if (
      flight.rawStatus === Status.Ticketed ||
      flight.rawStatus === Status.Canceled
    ) {
      actions.push({
        content: t("tripReviewLinks.resendEmail"),
        onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
        key: ActionLinkKey.RESEND_CONFIRMATION,
      });
    }

    actions.push({
      content: t("tripReviewLinks.getHelp"),
      onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      key: ActionLinkKey.HELP,
    });

    return actions;
  }, [
    ancillaries?.cfar?.id?.policyId,
    dispatch,
    enableAirExchange,
    flight,
    history,
    isCancelled,
    isPastTrips,
    onOpenModal,
    showAirCfar,
    showAirChfar,
    t,
  ]);

  const hasMajorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
    ScheduleChangeSeverity.Major;

  const hasMinorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
      ScheduleChangeSeverity.Minor ||
    (flight.status === Status.Modified &&
      !flight.bookedItinerary.scheduleChange);

  const flightInfoDetails = getFlightInfoDetails(
    flight,
    (date: string) =>
      dayjs(date).format(brand?.calendarDateLabelFormat || DATE_FORMAT),
    airportMap,
    airlineMap
  );

  const title = t("originToDestination", {
    origin: flightInfoDetails.origin,
    destination: flightInfoDetails.destination,
    interpolation: { escapeValue: false },
  });

  const titleTag = useMemo((): TagInfo | undefined => {
    switch (flight?.rawStatus) {
      case Status.Ticketed:
        return {
          label: t("confirmed"),
          type: TagColors.GREEN,
        };
      case Status.Booked:
      case Status.Pending:
        return {
          label: t("pending"),
          type: TagColors.GREY,
          iconName: IconName.InfoCircle,
          tooltipCopy: t("tooltip.pending") || "",
        };
      case Status.Canceled:
        return {
          label: t("cancelled"),
          type: TagColors.RED,
          iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
          tooltipCopy: hasMajorScheduleChange
            ? t("tooltip.cancelled") || ""
            : "",
        };
      case Status.Modified:
        return {
          label: t("itineraryModified"),
          type: hasMajorScheduleChange ? TagColors.RED : TagColors.YELLOW,
          iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
          tooltipCopy: hasMajorScheduleChange
            ? t("tooltip.modified") || ""
            : "",
        };
      case Status.Problem:
        return {
          label: t("problem"),
          type: TagColors.RED,
          iconName: IconName.InfoCircle,
          tooltipCopy: t("tooltip.problem"),
        };
      default:
        return undefined;
    }
  }, [flight?.rawStatus, hasMajorScheduleChange, t]);

  const passengers = flight.bookedItinerary.passengers.alone.map((ap) => {
    ap.person.type = ap.type;
    return ap.person;
  });

  const handleDisruptionProtectionCta = useCallback(() => {
    if (isNubank) {
      const shouldProceedToExerciseFlow =
        isDisruptionProtectionExercisable || isEligibleToBookReturn;
      if (shouldProceedToExerciseFlow) {
        setSelectedFlight(flight);
        dispatch(
          setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
        );
      } else {
        setSelectedFlight(flight);
        dispatch(
          setDisruptionExerciseProgress(DisruptionExerciseProgress.NotEligible)
        );
      }
    } else {
      onOpenModal(MyTripsModalTypes.ContactSupport);
    }
  }, [
    dispatch,
    setSelectedFlight,
    isDisruptionProtectionExercisable,
    isEligibleToBookReturn,
    flight,
    onOpenModal,
    isNubank,
  ]);

  const disruptionBanner = isDisruptionProtectionExercisable ? (
    <FlightDisruptionAlertBanner
      onClick={handleDisruptionProtectionCta}
      type={DisruptionAlertBannerType.DISRUPTED}
      productName={t("core-disruption.productName")}
    ></FlightDisruptionAlertBanner>
  ) : (
    isEligibleToBookReturn && (
      <FlightDisruptionAlertBanner
        onClick={handleDisruptionProtectionCta}
        type={DisruptionAlertBannerType.BOOK_RETURN}
        productName={t("core-disruption.productName")}
        destination={flightInfoDetails.destination}
      ></FlightDisruptionAlertBanner>
    )
  );

  const disruptionEntry = hasDisruptionProtection && (
    <div className="disruption-entry-container">
      <FlightDisruptionAlertBanner
        onClick={handleDisruptionProtectionCta}
        type={DisruptionAlertBannerType.ENTRY_POINT}
        productName={t("core-disruption.productName")}
      ></FlightDisruptionAlertBanner>
    </div>
  );

  if (!flight) {
    return null;
  }
  return (
    <div
      className={clsx(
        "mobile-trip-details-uber",
        "mobile-trip-details",
        getEnvVariables("clientName")
      )}
    >
      <div className="mobile-trip-details-header">
        <div className="mobile-trip-details-header-button" onClick={goBack}>
          {clientContext?.assets?.back ? (
            <img src={clientContext.assets.back} className="back-arrow" />
          ) : (
            <IconComponent
              ariaLabel="back icon"
              className="back-arrow"
              name={isNubank ? IconName.Chevron : IconName.ArrowRight}
            />
          )}
        </div>
        <div className="mobile-trip-details-header-title">
          {t("tripSummary")}
        </div>
      </div>
      <StatusBanner
        flight={flight}
        hasMajorScheduleChange={hasMajorScheduleChange}
        hasMinorScheduleChange={hasMinorScheduleChange}
        isMobile={true}
        setOpenModal={() => onOpenModal(MyTripsModalTypes.ScheduleChange)}
      />

      <div className="mobile-trip-details-info-container">
        {clientContext?.assets?.tripDetails ? (
          <div className="mobile-trip-details-icon-container">
            <img
              alt=""
              src={clientContext.assets.tripDetails}
              className="mobile-trip-details-icon"
            />
          </div>
        ) : null}
        {/* Top section */}
        <Slot
          id="trip-details-summary"
          flightInfoDetails={flightInfoDetails}
          onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
          confirmationNumbers={confirmationNumbers}
          title={title}
          titleTag={titleTag}
          isMultiAirline={isMultiAirline}
          flight={flight}
          disruptionBanner={disruptionBanner}
          component={
            <div className="mobile-trip-details-summary">
              <div className="mobile-trip-details-locations">{title}</div>
              <div className="mobile-trip-details-dates">
                {`${flightInfoDetails?.startDate} ${
                  flightInfoDetails?.endDate
                    ? ` - ${flightInfoDetails?.endDate}`
                    : ""
                }`}
              </div>
              <div
                className="mobile-trip-details-flight-details"
                onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
              >
                {titleTag ? (
                  <StatusTag
                    className="mobile-trip-details-status"
                    tagInfo={titleTag!}
                  />
                ) : null}
                <h3 className="mobile-trip-details-reservation-numbers-title">
                  {t("reservationNumbers")}
                </h3>

                <Divider className="mobile-trip-details-divider-reservation-numbers" />
                <div className="mobile-trip-details-confirmation-numbers-container">
                  {confirmationNumbers
                    ? confirmationNumbers.map((confirmationNumber, i) => {
                        // TODO: Update to be a filter instead of relying on the index
                        // the first confirmationNumber is the locator for the tenant - Uber wants this shown last
                        return i !== 0 ? (
                          <div
                            className="mobile-trip-details-confirmation-number"
                            key={`flight-confirmation-numbers-${i}`}
                          >
                            <p className="flight-confirmation-number">
                              {confirmationNumber.locator}
                            </p>
                            <p className="flight-confirmation-label">
                              {confirmationNumber.label}
                            </p>
                          </div>
                        ) : null;
                      })
                    : flightInfoDetails?.flightNumber}
                  <div className="mobile-trip-details-confirmation-number">
                    <p className="flight-confirmation-number">
                      {flightInfoDetails?.confirmationCode}
                    </p>
                    <p className="flight-confirmation-label">{t("uber")}</p>
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Divider className="mobile-trip-details-divider" />
        {/* Middle Section */}
        {isMultiAirline ? (
          <div className="mobile-trip-details-multi-airline">
            {t("multiAirlineTrip")}
          </div>
        ) : null}
        <div className="mobile-trip-details-info">
          <div className="mobile-trip-details-flight-summaries">
            <MobileFlightSummaryRowNew
              flightSummaryInfo={mapTitleKeys(
                getFlightSummaryInfo(true, flight, airportMap, airlineMap)
              )}
              onClick={() => onOpenModal(MyTripsModalTypes.OutboundItinerary)}
              iconSrc={clientContext.assets?.airplaneDepart}
              customIcon={
                isNubank ? (
                  <AirlineIcon
                    airlineCode={
                      mapTitleKeys(
                        getFlightSummaryInfo(
                          true,
                          flight,
                          airportMap,
                          airlineMap
                        )
                      )?.airlineCode
                    }
                  />
                ) : null
              }
              customExpandElement={
                isNubank ? (
                  <img
                    alt="Expand icon"
                    className="mobile-right-chevron"
                    src={clientContext.assets?.chevronDown}
                  />
                ) : undefined
              }
            />
            {getReturnSlice(flight.bookedItinerary) ? (
              <MobileFlightSummaryRowNew
                flightSummaryInfo={mapTitleKeys(
                  getFlightSummaryInfo(false, flight, airportMap, airlineMap)
                )}
                onClick={() => onOpenModal(MyTripsModalTypes.ReturnItinerary)}
                iconSrc={clientContext.assets?.airplaneArrive}
                customIcon={
                  isNubank ? (
                    <AirlineIcon
                      airlineCode={
                        mapTitleKeys(
                          getFlightSummaryInfo(
                            false,
                            flight,
                            airportMap,
                            airlineMap
                          )
                        )?.airlineCode
                      }
                    />
                  ) : null
                }
                customExpandElement={
                  isNubank ? (
                    <img
                      alt="Expand icon"
                      className="mobile-right-chevron"
                      src={clientContext.assets?.chevronDown}
                    />
                  ) : undefined
                }
              />
            ) : null}
          </div>
          <Divider className="mobile-trip-details-divider" />
          <div
            className={clsx("mobile-trip-details-passenger-info-container", {
              "no-padding": !flight.bookedItinerary?.paymentBreakdown,
            })}
          >
            <PassengerSummaryRow
              passengers={passengers as IPerson[]}
              iconSrc={clientContext?.assets?.passenger}
              review
            />
          </div>
          {flight.bookedItinerary?.paymentBreakdown ||
          flight.bookedItinerary?.paymentAmountInfo ? (
            <>
              <Divider className="mobile-trip-details-divider" />
              <div className="mobile-trip-details-total-price">
                <UberPriceSummaryRow
                  priceString={formatFiatCurrency(
                    flight.bookedItinerary?.sellingPricing.totalPricing.total
                      .fiat,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  iconSrc={clientContext?.assets?.card}
                  onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                  roundTrip={
                    getReturnSlice(flight.bookedItinerary) ? true : false
                  }
                  customExpandIcon={
                    isNubank ? (
                      <img src={clientContext?.assets?.chevron} alt="" />
                    ) : null
                  }
                />
              </div>
            </>
          ) : null}
        </div>

        {/* Bottom section */}
        <div className="mobile-trip-details-manage">
          <div className="mobile-trip-details-manage-title">
            {t("manageMyTrip")}
          </div>
          {disruptionEntry}
          <Slot
            id="trip-details-links"
            actions={actions}
            component={<MobileActionLinks actions={actions} />}
          />
        </div>

        <UberExchangeFlightModalContent
          flight={flight}
          onClose={goBack}
          open={selfServePage === ISelfServePage.exchange}
          openSelfServeCancel={() => setSelfServePage(ISelfServePage.cancel)}
          confirmationNumbers={confirmationNumbers}
        />
      </div>

      {status === Status.Canceled && hasFTC && (
        <Popover
          anchorEl={ftcPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-ftc-works-popover"
          onClose={closeFTCPopover}
          open={ftcPopoverOpen}
        >
          <Typography className="subtitle">{travelCreditCopy}</Typography>
        </Popover>
      )}
    </div>
  );
};
