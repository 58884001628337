import { Amenity } from "@b2bportal/lodging-api";
import { ReactComponent as Accessibility } from "../../assets/icons/hotel/amenities/Accessibility.svg";
import { ReactComponent as AirConditioning } from "../../assets/icons/hotel/amenities/AirConditioning.svg";
import { ReactComponent as AirportShuttle } from "../../assets/icons/hotel/amenities/AirportShuttle.svg";
import { ReactComponent as Baby } from "../../assets/icons/hotel/amenities/Baby.svg";
import { ReactComponent as Bar } from "../../assets/icons/hotel/amenities/Bar.svg";
import { ReactComponent as Bbq } from "../../assets/icons/hotel/amenities/Bbq.svg";
import { ReactComponent as Beach } from "../../assets/icons/hotel/amenities/Beach.svg";
import { ReactComponent as BedDoubleSolid } from "../../assets/icons/hotel/amenities/BedDoubleSolid.svg";
import { ReactComponent as Bell } from "../../assets/icons/hotel/amenities/Bell.svg";
import { ReactComponent as Breakfast } from "../../assets/icons/hotel/amenities/Breakfast.svg";
import { ReactComponent as BusinessServicesOutline } from "../../assets/icons/hotel/amenities/BusinessServicesOutline.svg";
import { ReactComponent as Coffee } from "../../assets/icons/hotel/amenities/Coffee.svg";
import { ReactComponent as CrossOut } from "../../assets/icons/hotel/amenities/CrossOut.svg";
import { ReactComponent as Essential } from "../../assets/icons/hotel/amenities/Essential.svg";
import { ReactComponent as Fallback } from "../../assets/icons/hotel/amenities/Fallback.svg";
import { ReactComponent as Gym } from "../../assets/icons/hotel/amenities/Gym.svg";
import { ReactComponent as HairDryer } from "../../assets/icons/hotel/amenities/HairDryer.svg";
import { ReactComponent as Heat } from "../../assets/icons/hotel/amenities/Heat.svg";
import { ReactComponent as HotTub } from "../../assets/icons/hotel/amenities/HotTub.svg";
import { ReactComponent as Kitchen } from "../../assets/icons/hotel/amenities/Kitchen.svg";
import { ReactComponent as Laptop } from "../../assets/icons/hotel/amenities/Laptop.svg";
import { ReactComponent as Laundry } from "../../assets/icons/hotel/amenities/Laundry.svg";
import { ReactComponent as Outdoors } from "../../assets/icons/hotel/amenities/Outdoors.svg";
import { ReactComponent as Parking } from "../../assets/icons/hotel/amenities/Parking.svg";
import { ReactComponent as PetFriendlyOutline } from "../../assets/icons/hotel/amenities/PetFriendlyOutline.svg";
import { ReactComponent as Pool } from "../../assets/icons/hotel/amenities/Pool.svg";
import { ReactComponent as Restaurant } from "../../assets/icons/hotel/amenities/Restaurant.svg";
import { ReactComponent as Spa } from "../../assets/icons/hotel/amenities/Spa.svg";
import { ReactComponent as Tv } from "../../assets/icons/hotel/amenities/Tv.svg";
import { ReactComponent as Wifi } from "../../assets/icons/hotel/amenities/Wifi.svg";
import { SvgrComponent } from "../typings";

interface SvgrComponentWithAlt extends SvgrComponent {
  alt?: string;
}

const amenityIconCollection: {
  [key in Amenity]: SvgrComponentWithAlt;
} = {
  [Amenity.Accessibility]: Accessibility,
  [Amenity.AccessibleBathroom]: Accessibility,
  [Amenity.AdultsOnly]: CrossOut,
  [Amenity.AirConditioning]: AirConditioning,
  [Amenity.AirportTransportation]: AirportShuttle,
  [Amenity.AirportTransportationAvailableGeneral]: AirportShuttle,
  [Amenity.Bar]: Bar,
  [Amenity.BarbecueGrill]: Bbq,
  [Amenity.BarOrLounge]: Bar,
  [Amenity.Beach]: Beach,
  [Amenity.BrailleOrRaisedSignage]: Accessibility,
  [Amenity.BreakfastIncluded]: Breakfast,
  [Amenity.BusinessCenter]: BusinessServicesOutline,
  [Amenity.BusinessFacilities]: BusinessServicesOutline,
  [Amenity.Childcare]: Baby,
  [Amenity.CoffeeMaker]: Coffee,
  [Amenity.CoffeeTea]: Coffee,
  [Amenity.ComputerStation]: Laptop,
  [Amenity.ConferenceSpace]: BusinessServicesOutline,
  [Amenity.DryCleaningLaundryService]: Laundry,
  [Amenity.Entertainment]: Tv,
  [Amenity.FitnessCenter]: Gym,
  [Amenity.FitnessFacilities]: Gym,
  [Amenity.FreeAirportTransportation]: AirportShuttle,
  [Amenity.FreeBreakfast]: Breakfast,
  [Amenity.FreeSelfParking]: Parking,
  [Amenity.FreeWifi]: Wifi,
  [Amenity.FullServiceSpa]: Spa,
  [Amenity.HairDryer]: HairDryer,
  [Amenity.Heating]: Heat,
  [Amenity.HomeEssentials]: Essential,
  [Amenity.HomeInfo]: BedDoubleSolid,
  [Amenity.HotTub]: HotTub,
  [Amenity.IndoorPool]: Pool,
  [Amenity.InRoomAccessibility]: Accessibility,
  [Amenity.KidFriendly]: Baby,
  [Amenity.KitchenEssentials]: Kitchen,
  [Amenity.Laundry]: Laundry,
  [Amenity.LaundryFacilities]: Laundry,
  [Amenity.MeetingRooms]: BusinessServicesOutline,
  [Amenity.NumberOfBarsOrLounges]: Bar,
  [Amenity.NumberOfRestaurants]: Restaurant,
  [Amenity.OutdoorPool]: Pool,
  [Amenity.OutdoorSeasonalPool]: Pool,
  [Amenity.OutdoorSpace]: Outdoors,
  [Amenity.PaidBreakfast]: Breakfast,
  [Amenity.PaidSelfParking]: Parking,
  [Amenity.PaidValetParking]: Parking,
  [Amenity.Parking]: Parking,
  [Amenity.ParkingAvailableGeneral]: Parking,
  [Amenity.PetFriendly]: PetFriendlyOutline,
  [Amenity.PetsAllowed]: PetFriendlyOutline,
  [Amenity.PetsAllowedNoFees]: PetFriendlyOutline,
  [Amenity.PetsOnlyCats]: PetFriendlyOutline,
  [Amenity.PetsOnlyDogs]: PetFriendlyOutline,
  [Amenity.PetsOnlyDogsAndCats]: PetFriendlyOutline,
  [Amenity.Pool]: Pool,
  [Amenity.PoolGeneral]: Pool,
  [Amenity.PoolsideBar]: Bar,
  [Amenity.Restaurant]: Restaurant,
  [Amenity.RollInShower]: Accessibility,
  [Amenity.RoomWifi]: Wifi,
  [Amenity.RoundTheClockCheckIn]: Bell,
  [Amenity.SomeAccessibilityFeaturesAvailableGeneral]: Accessibility,
  [Amenity.Spa]: Spa,
  [Amenity.SpaTreatmentRoom]: Spa,
  [Amenity.SteamRoom]: Spa,
  [Amenity.SwimmingPool]: Pool,
  [Amenity.TwentyFourHourFitnessFacilities]: Gym,
  [Amenity.TwentyFourHourFrontDesk]: Bell,
  [Amenity.WheelchairAccessibleParking]: Accessibility,
  [Amenity.WheelchairAccessiblePathOfTravel]: Accessibility,
  [Amenity.Workspace]: BusinessServicesOutline,

  // TODO: These amenities need icons.
  [Amenity.Arcade]: Fallback,
  [Amenity.AtmBanking]: Fallback,
  [Amenity.BeachEssentials]: Fallback,
  [Amenity.Casino]: Fallback,
  [Amenity.ConciergeServices]: Fallback,
  [Amenity.ElectricCarCharging]: Fallback,
  [Amenity.ExpressCheckIn]: Fallback,
  [Amenity.Garden]: Fallback,
  [Amenity.Golf]: Fallback,
  [Amenity.HairSalon]: Fallback,
  [Amenity.HikingOrBikingNearby]: Fallback,
  [Amenity.Library]: Fallback,
  [Amenity.LuggageStorage]: Fallback,
  [Amenity.Nightclub]: Fallback,
  [Amenity.PorterBellhop]: Fallback,
  [Amenity.SafeDepositBoxFrontDesk]: Fallback,
  [Amenity.Sauna]: Fallback,
  [Amenity.SnackOrDeli]: Fallback,
  [Amenity.Tennis]: Fallback,
  [Amenity.Terrace]: Fallback,
  [Amenity.ToursTicketAssistance]: Fallback,
  [Amenity.WaterPark]: Fallback,
};

export { amenityIconCollection as AmenityIconCollection };
