import {
  CoreHotelsComponent,
  HotelCardPriceComponentProps,
} from "@b2bportal/core-types";
import { createSlotSystem } from "../../common";

type HotelSlotProps = {
  [CoreHotelsComponent.HotelCardPrice]: HotelCardPriceComponentProps;
};

export const { SlotProvider: HotelsSlotProvider, Slots: HotelsSlot } =
  createSlotSystem<HotelSlotProps>();
