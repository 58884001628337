import {
  FlightSearchQueryParams,
  FlightSearchQueryParamValues,
  IPassengerCounts,
  ITripTerminus,
} from "@b2bportal/core-types";
import { formatDateForUrl } from "@hopper-b2b/common-utils";
import {
  FLIGHT_SHOP_TYPE,
  FlightShopType,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom-v5-compat";
import { FlightSearchValues } from "../../search/store";
import { getSearchState } from "../../search/store/selectors";

export const useGetShopQueryParams =
  (): Partial<FlightSearchQueryParamValues> => {
    const [searchParams] = useSearchParams();

    const origin =
      searchParams.get(FlightSearchQueryParams.ORIGIN) ?? undefined;
    const destination =
      searchParams.get(FlightSearchQueryParams.DESTINATION) ?? undefined;
    const departureDate =
      searchParams.get(FlightSearchQueryParams.DEPARTURE_DATE) ?? undefined;
    const returnDate =
      searchParams.get(FlightSearchQueryParams.RETURN_DATE) ?? undefined;
    const tripCategory =
      searchParams.get(FlightSearchQueryParams.TRIP_CATEGORY) ?? undefined;
    const stopOptions =
      searchParams.get(FlightSearchQueryParams.STOP_OPTIONS) ?? undefined;
    const adultCount =
      searchParams.get(FlightSearchQueryParams.ADULT_COUNT) ?? undefined;
    const childCount =
      searchParams.get(FlightSearchQueryParams.CHILD_COUNT) ?? undefined;
    const infantInSeatCount =
      searchParams.get(FlightSearchQueryParams.INFANT_IN_SEAT_COUNT) ??
      undefined;
    const infantOnLapCount =
      searchParams.get(FlightSearchQueryParams.INFANT_IN_LAP_COUNT) ??
      undefined;

    return {
      origin,
      destination,
      departureDate,
      returnDate,
      tripCategory,
      stopOptions,
      adultCount,
      childCount,
      infantInSeatCount,
      infantOnLapCount,
    };
  };

/**
 * Hook to check if the current search is the same as the one in the URL
 * by comparing the search values in the redux store with the URL search params
 */

export const useIsSameSearch = () => {
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    adultCount,
    childCount,
    infantInSeatCount,
    infantOnLapCount,
  } = useGetShopQueryParams();

  const searchValues = useSelector(getSearchState);

  /** TODO: compare the rest of the search values related with passenger selection */
  const isSameSearch =
    isSameLocation(origin, searchValues.origin) &&
    isSameLocation(destination, searchValues.destination) &&
    isSameDate(departureDate, searchValues.departureDate) &&
    isSameDate(returnDate, searchValues.returnDate) &&
    searchValues.tripCategory === tripCategory &&
    isSameNumber(adultCount, searchValues.passengerCounts.adultsCount) &&
    isSameNumber(childCount, searchValues.passengerCounts.childrenCount) &&
    isSameNumber(
      infantInSeatCount,
      searchValues.passengerCounts.infantsInSeatCount
    ) &&
    isSameNumber(
      infantOnLapCount,
      searchValues.passengerCounts.infantsOnLapCount
    );

  return isSameSearch;
};
const isSameNumber = (param: string | undefined, value: number) => {
  const parsedParam = param == null || param === "" ? 0 : parseInt(param);
  const paramAsNumber = isNaN(parsedParam) ? 0 : parsedParam;
  const valueAsNumber = isNaN(value) ? 0 : value;
  return valueAsNumber === paramAsNumber;
};

const isSameLocation = (code?: string, location?: ITripTerminus) => {
  return (
    (code == null && location == null) ||
    (code != null && location != null && location.id.code.code === code)
  );
};

const isSameDate = (queryParamDate?: string, storeDate?: string) => {
  return (
    (queryParamDate == null && storeDate == null) ||
    (queryParamDate != null &&
      storeDate != null &&
      storeDate === queryParamDate)
  );
};

export type BuildFlightShopQueryArgs =
  | {
      tripCategory: TripCategory.ROUND_TRIP;
      origin: ITripTerminus;
      destination: ITripTerminus;
      passengerCounts?: IPassengerCounts;
      departureDate: Date | string;
      returnDate: Date | string;
    }
  | {
      tripCategory: TripCategory.ONE_WAY;
      origin: ITripTerminus;
      destination: ITripTerminus;
      passengerCounts?: IPassengerCounts;
      departureDate: Date | string;
    };

export const buildFlightShopQuery = (params: BuildFlightShopQueryArgs) => {
  const { passengerCounts, origin, destination, departureDate } = params;
  const departureDateParam = formatDateForUrl(departureDate);

  const flightShopQueryObject = {
    [FlightSearchQueryParams.ORIGIN]: origin.id.code.code,
    [FlightSearchQueryParams.DESTINATION]: destination.id.code.code,
    [FlightSearchQueryParams.DEPARTURE_DATE]: departureDateParam,
    ...(params.tripCategory === TripCategory.ROUND_TRIP
      ? {
          [FlightSearchQueryParams.RETURN_DATE]: formatDateForUrl(
            params.returnDate
          ),
        }
      : {}),
    [FlightSearchQueryParams.TRIP_CATEGORY]: params.tripCategory,
    [FlightSearchQueryParams.STOP_OPTIONS]: SliceStopCountFilter.ANY_NUMBER,
    [FLIGHT_SHOP_TYPE]: FlightShopType.DEFAULT,
    [FlightSearchQueryParams.ADULT_COUNT]:
      passengerCounts?.adultsCount.toString() ?? "",
    [FlightSearchQueryParams.CHILD_COUNT]:
      passengerCounts?.childrenCount.toString() ?? "",
    [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]:
      passengerCounts?.infantsOnLapCount.toString() ?? "",
    [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]:
      passengerCounts?.infantsInSeatCount.toString() ?? "",
  };

  const shopQuery = new URLSearchParams(flightShopQueryObject).toString();
  return shopQuery;
};

export const buildFlightShopValuesFromQueryParams = (
  queryParams: Partial<FlightSearchQueryParamValues>
): Omit<FlightSearchValues, "origin" | "destination"> => {
  const {
    departureDate,
    returnDate,
    tripCategory,
    adultCount,
    childCount,
    infantInSeatCount,
    infantOnLapCount,
  } = queryParams;

  // Origin and destination are not returned given you can not build a location from a code
  return {
    departureDate,
    returnDate,
    tripCategory:
      tripCategory === TripCategory.ONE_WAY
        ? TripCategory.ONE_WAY
        : TripCategory.ROUND_TRIP,
    passengerCounts: {
      adultsCount: adultCount != null ? parseInt(adultCount) : 0,
      childrenCount: childCount != null ? parseInt(childCount) : 0,
      infantsInSeatCount:
        infantInSeatCount != null ? parseInt(infantInSeatCount) : 0,
      infantsOnLapCount:
        infantOnLapCount != null ? parseInt(infantOnLapCount) : 0,
    },
  };
};
