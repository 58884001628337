export const CORE_DISRUPTION_NAME = "core-disruption";

export enum CoreDisruptionComponent {
  DisruptionOfferSection = "DisruptionOfferSection",
  DisruptionUniversalExercise = "DisruptionUniversalExercise",
  DisruptionExerciseContainer = "DisruptionExerciseContainer",
  DisruptionExerciseButton = "DisruptionExerciseButton",
  DisruptionExerciseFooter = "DisruptionExerciseFooter",
  DisruptionExerciseHeader = "DisruptionExerciseHeader",
  DisruptionExerciseMoreInfo = "DisruptionExerciseMoreInfo",
  DisruptionExerciseLandingPage = "DisruptionExerciseLandingPage",
  DisruptionExerciseOptionsCard = "DisruptionExerciseOptionsCard",
  DisruptionExercisePage = "DisruptionExercisePage",
  DisruptionExercisePolicyDetails = "DisruptionExercisePolicyDetails",
  DisruptionExercisePrimaryButton = "DisruptionExercisePrimaryButton",
  DisruptionExerciseRefundConfirmationPage = "DisruptionExerciseRefundConfirmationPage",
  DisruptionExerciseRefundInformationCard = "DisruptionExerciseRefundInformationCard",
  DisruptionExerciseRefundReviewContainer = "DisruptionExerciseRefundReviewContainer",
  DisruptionExerciseRefundReviewPage = "DisruptionExerciseRefundReviewPage",
  DisruptionExerciseRefundSummaryCard = "DisruptionExerciseRefundSummaryCard",
  DisruptionExerciseSelection = "DisruptionExerciseSelection",
  DisruptionExerciseSummaryCard = "DisruptionExerciseSummaryCard",
  DisruptionInfoBanner = "DisruptionInfoBanner",
  DisruptionExpandableContainer = "DisruptionExpandableContainer",
  DisruptionExerciseLearnMoreContent = "DisruptionExerciseLearnMoreContent",
  DisruptionExercisePopup = "DisruptionExercisePopup",
  DisruptionExercisePageContent = "DisruptionExercisePageContent",
  DisruptionExerciseBulletPoint = "DisruptionExerciseBulletPoint",
  DisruptionExerciseInformationCard = "DisruptionExerciseInformationCard",
  HowItWorksDialog = "HowItWorksDialog",
  FlightDisruptionAlertBanner = "FlightDisruptionAlertBanner",
  DisruptionContactSupport = "DisruptionContactSupport",
  DisruptionExerciseNotEligiblePage = "DisruptionExerciseNotEligiblePage",
  DisruptionExerciseLoading = "DisruptionExerciseLoading",
}
