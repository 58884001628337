import React, { createContext, useContext, PropsWithChildren } from "react";

type SlotComponentProps = Record<string, any>;

type SlotComponents<T> = {
  [K in keyof T]: React.ComponentType<T[K]>;
};

type SlotContext<T> = {
  components: SlotComponents<T>;
  providerName: string;
};

type SlotProviderProps<T> = PropsWithChildren<{
  context: SlotContext<T>;
}>;

export const createSlotSystem = <T extends SlotComponentProps>() => {
  const SlotContext = createContext<SlotContext<T> | undefined>(undefined);

  const SlotProvider = ({ context, children }: SlotProviderProps<T>) => {
    return (
      <SlotContext.Provider value={context}>{children}</SlotContext.Provider>
    );
  };

  const useSlots = () => {
    const context = useContext(SlotContext);
    if (!context) {
      throw new Error("useSlots must be used within a SlotProvider");
    }
    return context;
  };

  const createSlotComponent = <K extends keyof T>(name: K) => {
    const Slot: React.FC<T[K] & Record<string, unknown>> = (props) => {
      const slots = useSlots();
      const Component = slots.components[name];
      return <Component {...props} />;
    };

    Slot.displayName = `Slot(${String(name)})`;
    return Slot;
  };

  const createSlotComponents = () => {
    return new Proxy(
      {},
      {
        get: (_, prop: string) => {
          return createSlotComponent(prop as keyof T);
        },
      }
    ) as SlotComponents<T>;
  };

  const Slots = createSlotComponents();

  return {
    SlotProvider,
    Slots,
    useSlots,
  };
};
