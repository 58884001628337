import { PropsWithChildren, useState } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useLocation } from "react-router-dom-v5-compat";
import { ITripTerminus } from "@hopper-b2b/types";
import { noop } from "@hopper-b2b/utilities";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import LocationMarker from "../../../assets/client/location-marker.svg";
import CloseIcon from "../../../assets/client/close.svg";
import LocationMarkerWhite from "../../../assets/client/darkMode/location-marker-white.svg";
import AirplaneOutline from "../../../assets/client/airplane-outline.svg";
import AirplaneOutlineWhtie from "../../../assets/client/darkMode/airplane-arrive-white.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import "./styles.scss";
import { AccessibleAutocomplete } from "./AccessibleAutocomplete";
import {
  DestinationAutocomplete,
  OriginAutocomplete,
} from "./ConnectedAccessibleAutocomplete";

interface FlightShopLocationAutocompleteProps {
  hideIcon?: boolean;
  className?: string;
  customIcon?: JSX.Element;
  customClearIcon?: JSX.Element;
  getOptionSelected: (
    option: ITripTerminus & { categoryLabel: string },
    value: ITripTerminus
  ) => boolean;
  label: string;
  loadingText?: string;
  noOptionsText?: JSX.Element;
  onBlur: () => void;
  onFocus: () => void;
  popperClassName?: string;
  popupIcon?: JSX.Element;
  setValue: (value: ITripTerminus) => void;
  value: ITripTerminus;
}

export const FlightShopLocationOriginAutocomplete = ({
  getOptionSelected,
  onBlur,
  onFocus,
  popperClassName,
  popupIcon,
  setValue,
  value,
}: FlightShopLocationAutocompleteProps) => {
  const flightSearchRegex = /.*\/flights\/search/g;

  const { t } = useI18nContext();
  const { pathname } = useLocation();
  const inFlightSearch = flightSearchRegex.test(pathname);
  const autoText = {
    loadingText: `${t("searching")}...`,
    noOptionsText: t("noOptions"),
    whereFrom: t("searchControl.whereFrom"),
    whereTo: t("searchControl.whereTo"),
  };

  const isDarkMode = useDarkModePreferred();
  const LocationIcon = isDarkMode ? LocationMarkerWhite : LocationMarker;
  const inputIcon = <img src={LocationIcon} className="icon" alt="" />;

  return (
    <LocationSearchWrapperWithModal
      inputIcon={inputIcon}
      value={value}
      inFlightSearch={inFlightSearch}
    >
      <OriginAutocomplete
        hideIcon={false}
        className="origin-auto-complete b2b mobile"
        customIcon={inputIcon}
        getOptionSelected={getOptionSelected}
        label={autoText.whereFrom}
        loadingText={autoText.loadingText}
        noOptionsText={<Typography>{autoText.noOptionsText}</Typography>}
        onBlur={onBlur}
        onFocus={onFocus}
        popperClassName={clsx(
          "mobile",
          "origin-dropdown",
          "modal",
          inFlightSearch ? "flight-search" : "flight-shop",
          popperClassName
        )}
        popupIcon={popupIcon}
        setValue={setValue}
        value={value}
      />
    </LocationSearchWrapperWithModal>
  );
};

export const FlightShopLocationDestinationAutocomplete = ({
  getOptionSelected,
  onBlur,
  onFocus,
  popperClassName,
  popupIcon,
  setValue,
  value,
}: FlightShopLocationAutocompleteProps) => {
  const flightSearchRegex = /.*\/flights\/search/g;
  const { t } = useI18nContext();
  const { pathname } = useLocation();
  const inFlightSearch = flightSearchRegex.test(pathname);
  const autoText = {
    loadingText: `${t("searching")}...`,
    noOptionsText: t("noOptions"),
    whereFrom: t("searchControl.whereFrom"),
    whereTo: t("searchControl.whereTo"),
  };

  const isDarkMode = useDarkModePreferred();
  const DestinationIcon = isDarkMode ? AirplaneOutlineWhtie : AirplaneOutline;
  const inputIcon = <img src={DestinationIcon} className="icon" alt="" />;

  return (
    <LocationSearchWrapperWithModal
      inputIcon={inputIcon}
      value={value}
      inFlightSearch={inFlightSearch}
    >
      <DestinationAutocomplete
        hideIcon={false}
        className="destination-auto-complete b2b mobile"
        customIcon={inputIcon}
        customClearIcon={
          <IconComponent
            className="clear-search-icon"
            name={IconName.XCircleFilled}
          />
        }
        getOptionSelected={getOptionSelected}
        label={autoText.whereTo}
        loadingText={autoText.loadingText}
        noOptionsText={<Typography>{autoText.noOptionsText}</Typography>}
        onBlur={onBlur}
        onFocus={onFocus}
        popperClassName={clsx(
          "mobile",
          "destination-dropdown",
          "modal",
          inFlightSearch ? "flight-search" : "flight-shop",
          popperClassName
        )}
        popupIcon={popupIcon}
        setValue={setValue}
        value={value}
      />
    </LocationSearchWrapperWithModal>
  );
};

interface LocationSearchWrapperWithModalProps
  extends Partial<FlightShopLocationAutocompleteProps>,
    PropsWithChildren {
  inputIcon: JSX.Element;
  inFlightSearch: boolean;
}

const LocationSearchWrapperWithModal = ({
  value,
  children,
  inputIcon,
  inFlightSearch,
}: LocationSearchWrapperWithModalProps) => {
  const { t } = useI18nContext();
  const [open, setOpen] = useState<boolean>(false);

  return inFlightSearch ? (
    children
  ) : (
    <>
      <button
        className="b2b-autocomplete-wrapper-button"
        onClick={() => setOpen(true)}
      >
        {inFlightSearch ? (
          <AccessibleAutocomplete
            value={value}
            valueCategories={[]}
            setValue={noop}
            fetchValueCategories={noop}
            getOptionSelected={function () {
              return true;
            }}
            label={value?.label || ""}
            customIcon={inputIcon}
          />
        ) : (
          <div className="flight-shop-modal-button">
            <div className="icon">{inputIcon}</div>
            <div className="label">{value?.label || ""}</div>
          </div>
        )}
      </button>
      <MobilePopoverCard
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullScreen
        className="nubank-autocomplete-popover"
        contentClassName="autocomplete-popover-wrapper"
        paperClassName={"nubank-autocomplete-popover-paper"}
        topLeftButton={
          <ActionLink
            className={"autocomplete-popover-header-back-button"}
            onClick={() => {
              setOpen(false);
            }}
            content={<img src={CloseIcon} alt={t("close.imageAlt")} />}
          />
        }
      >
        <h1 className="header-text">{t("flights")}</h1>
        <div>{children}</div>
        <MobileFloatingButton
          onClick={() => {
            setOpen(false);
          }}
          className="nubank-flight-shop-location-continue"
        >
          {t("continue")}
        </MobileFloatingButton>
      </MobilePopoverCard>
    </>
  );
};
