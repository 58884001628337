import {
  DisruptionProtectionDisruptionProtectionPolicyDetails,
  FiatPrice,
} from "@b2bportal/air-booking-api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import React, { useCallback } from "react";
import { useDeviceType } from "@b2bportal/core-utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { DisruptionExerciseContainer } from "../DisruptionExerciseContainer";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExercisePolicyDetails.module.scss";
import {
  DisruptionContractStatus,
  EventCovered,
} from "../../../../features/exercise/store/slice";
import dayjs from "dayjs";

interface DisruptionExercisePolicyDetailsProps {
  policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails;
  expanded: boolean;
  onExpand: () => void;
  termsLink: string;
}

const TRANS_PATH =
  "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails";

export const DisruptionExercisePolicyDetails = (
  props: DisruptionExercisePolicyDetailsProps
) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExercisePolicyDetails;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { language, t, formatFiatCurrency } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const formatCurrency = useCallback(
    (value?: FiatPrice) => {
      return formatFiatCurrency(value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    },
    [formatFiatCurrency]
  );

  const policyRow = (
    title: string,
    subtitle: string,
    separator?: JSX.Element
  ) => {
    return (
      <div className={cn("policyRow")}>
        <div className={cn("policyRowContent")}>
          <span className={cn("policyRowTitle")}>{title}</span>
          <span className={cn("policyRowsubtitle")}>{subtitle}</span>
        </div>
        {separator}
      </div>
    );
  };

  function delayThreshold(
    policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails
  ) {
    const delayThreshold = policyDetails.delayThreshold;
    if (delayThreshold) {
      return (delayThreshold / 60).toString();
    }
    //default to 3 hours
    return "3";
  }

  function rebookingLimit(
    policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails
  ) {
    const rebookingLimit = policyDetails.rebookingLimit;
    if (rebookingLimit) {
      return formatCurrency(rebookingLimit.fiat);
    }
    //default to $2000
    return formatCurrency({
      value: 2000,
      currencyCode: "USD",
      currencySymbol: "$",
    });
  }

  const eventsCovered = props.policyDetails.eventsCovered
    .map((item) => {
      if (item.EventCovered === EventCovered.CANCELLATIONS) {
        return t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.cancellations"
        );
      } else if (item.EventCovered === EventCovered.DELAYS) {
        return t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.delays"
        );
      } else if (item.EventCovered === EventCovered.MISSED_CONNECTIONS) {
        return t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.missedConnections"
        );
      } else {
        return null;
      }
    })
    .filter((message) => message !== null)
    .join(", ");

  const policyStatus =
    props.policyDetails.policyStatus.DisruptionContractStatus ===
    DisruptionContractStatus.ANY_EXERCISE_ELIGIBLE
      ? t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.eligible"
        )
      : t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.notEligible"
        );

  const policyExpiration = formatDateTime(
    dayjs(props.policyDetails.policyExpiration).toDate(),
    language,
    DateTimeFormatStyle.MediumDate
  );

  const policyContent = (
    <div className={cn("polictyDetailsContent")}>
      {policyRow(
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.eventsCovered"
        ),
        eventsCovered,
        <hr></hr>
      )}
      {policyRow(
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.delayThreshold"
        ),
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.hours",
          {
            delayThreshold: delayThreshold(props.policyDetails),
          }
        ),
        <hr></hr>
      )}
      {policyRow(
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.rebookingLimit"
        ),
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.perTraveler",
          {
            rebookingLimit: rebookingLimit(props.policyDetails),
          }
        ),
        <hr></hr>
      )}
      {policyRow(
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.policyStatus"
        ),
        policyStatus,
        <hr></hr>
      )}
      {policyRow(
        t(
          "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.policyExpiration"
        ),
        policyExpiration
      )}

      <div className={cn("policyFooterRowContainer")}>
        <Trans
          i18nKey={`${TRANS_PATH}.viewDetails`}
          components={[
            <span className={cn("policyDetailsFooter")}></span>,
            <a href={props.termsLink} target="_blank" rel="noreferrer"></a>,
          ]}
        />
      </div>
    </div>
  );

  const mobileHeader = (
    <div
      className={cn("mobileHeader")}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        props.onExpand();
      }}
    >
      <hr></hr>
      <div className={cn("mobileHeaderContent")}>
        <div className={cn("titleContainerMobile")}>
          <span className={cn("mobileTitle")}>
            {t(
              "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.title"
            )}
          </span>
        </div>
        <FontAwesomeIcon
          className={cn("expandIcon")}
          icon={props.expanded ? faCaretUp : faCaretDown}
          color="primary"
        />
      </div>
    </div>
  );

  const mobileContent = props.expanded ? (
    <DisruptionExerciseContainer
      content={
        <div>
          {mobileHeader}
          {policyContent}
        </div>
      }
    ></DisruptionExerciseContainer>
  ) : (
    mobileHeader
  );

  const desktopContent = (
    <DisruptionExerciseContainer
      content={
        <div className={cn("policyDetailsDesktopContainer")}>
          <span className={cn("title")}>
            {t(
              "core-disruption.disruptionUniversalExercise.landingPage.polciyDetails.title"
            )}
          </span>
          {policyContent}
        </div>
      }
    />
  );

  return (
    <div className={cn("DisruptionExercisePolicyDetails")}>
      {isDesktopAndUp ? desktopContent : mobileContent}
    </div>
  );
};

export default DisruptionExercisePolicyDetails;
