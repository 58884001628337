import { useI18nContext } from "@hopper-b2b/i18n";
import { PhoneNumberValidatorEnum } from "@hopper-b2b/types";
import {
  ActionLinks,
  B2BTextField,
  IActionLink,
  PhoneInputField,
  Slot,
} from "@hopper-b2b/ui";
import {
  emailRegex as defaultEmailRegex,
  phoneRegex as defaultPhoneRegex,
  validateEmail,
} from "@hopper-b2b/utilities";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { IContactInfo } from "../../../../types/common";
import "./styles.scss";
import { updateContactInfo } from "./updateUtils";
import { validatePhone } from "./validateUtils";

export interface IContactInfoFormProps {
  className?: string;
  isMobile?: boolean;
  title?: string;
  subtitle?: string;
  contactInfo: IContactInfo | null | undefined;
  onContactInfoChange?: (contactInfo: IContactInfo) => void;
  disabled?: boolean;
  hideEmailAddress?: boolean;
  hidePhoneNumber?: boolean;
  actions?: IActionLink[];
  headerVariant?: Variant;
  header?: boolean;
  showEditAction?: boolean;
  onEdit?: () => void;
  inHotel?: boolean;
  disableRipple?: boolean;
  validate?: boolean;
  emailRegex?: RegExp;
  phoneRegex?: RegExp;
  checkPhoneMinLength?: boolean;
  minPhoneLength?: number;
  onFocusEmail?: (
    value: string,
    event?: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlurEmail?: (value: string) => void;
}

export const ContactInfoForm = ({
  contactInfo,
  onContactInfoChange,
  validate = true,
  emailRegex = defaultEmailRegex,
  inHotel,
  phoneRegex = defaultPhoneRegex,
  checkPhoneMinLength,
  minPhoneLength,
  ...rest
}: IContactInfoFormProps) => {
  const { t, brand } = useI18nContext();

  const [countryCode, setCountryCode] = useState(
    contactInfo?.countryCode || brand.preferredAreaCode || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    contactInfo?.phoneNumber || ""
  );
  const [email, setEmail] = useState(contactInfo?.email || "");

  const [emailError, setEmailError] = useState(
    contactInfo && contactInfo.email
      ? emailRegex.test(contactInfo.email)
        ? ""
        : t("contactInfoEmailError")
      : ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState(
    contactInfo && contactInfo.phoneNumber
      ? phoneRegex.test(contactInfo.phoneNumber)
        ? ""
        : t("contactInfoPhoneError")
      : ""
  );

  const updateContactInfoCallback = useCallback(
    (contactInfo: IContactInfo) => {
      updateContactInfo({
        ...contactInfo,
        callback: onContactInfoChange,
      });
    },
    [onContactInfoChange]
  );

  const validateEmailCallback = useCallback(
    (_email: string) => {
      validateEmail({
        validate,
        emailRegex,
        email: _email,
        onInvalid: (key: string) => key && setEmailError(t(key)),
        onValid: () => setEmailError(""),
      });
    },
    [validate, emailRegex, t]
  );

  const onEmailChanged = useCallback(
    (_email: string) => {
      setEmail(_email);
      validateEmailCallback(_email);

      updateContactInfoCallback({ countryCode, phoneNumber, email: _email });
    },
    [countryCode, phoneNumber, updateContactInfoCallback, validateEmailCallback]
  );

  const validatePhoneNumberCallback = useCallback(
    (_phoneNumber: string) => {
      validatePhone({
        validate,
        phoneRegex,
        minPhoneLength,
        checkPhoneMinLength,
        phoneNumber: _phoneNumber,
        onInvalid: (key: string) => {
          key && setPhoneNumberError(t(key));
        },
        onValid: () => setPhoneNumberError(""),
      });
    },
    [validate, phoneRegex, minPhoneLength, checkPhoneMinLength, t]
  );

  const onPhoneNumberChanged = useCallback(
    (
      _phoneNumber: string,
      _countryCode: string,
      customIsValid?: (number: string) => PhoneNumberValidatorEnum
    ) => {
      if (customIsValid) {
        switch (customIsValid(_phoneNumber)) {
          case PhoneNumberValidatorEnum.CUSTOM_ERROR:
            setPhoneNumberError(t("invalidPhoneNumberFormat"));
            break;
          case PhoneNumberValidatorEnum.VALID:
            setPhoneNumberError("");
            break;
          case PhoneNumberValidatorEnum.DEFAULT_ERROR:
            setPhoneNumberError(t("contactInfoPhoneError"));
            break;
          case PhoneNumberValidatorEnum.NOT_VALIDATED:
            validatePhoneNumberCallback(_phoneNumber);
            break;
        }
      } else {
        validatePhoneNumberCallback(_phoneNumber);
      }

      setPhoneNumber(_phoneNumber);
      setCountryCode(_countryCode);

      updateContactInfoCallback({
        countryCode: _countryCode,
        phoneNumber: _phoneNumber,
        email,
      });
    },
    [email, t, updateContactInfoCallback, validatePhoneNumberCallback]
  );

  return (
    <Box
      className={clsx("contact-info-form-container", rest.className, {
        mobile: rest.isMobile,
        disabled: rest.disabled,
        "has-error": !!emailError || !!phoneNumberError,
        "has-actions": !!rest.actions,
        "display-only": rest.disabled,
        hotel: inHotel,
      })}
    >
      <Slot
        id="checkout-contact-info-form"
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phone={phoneNumber}
        phoneError={phoneNumberError}
        onPhoneNumberChanged={onPhoneNumberChanged}
        email={email}
        emailError={emailError}
        onEmailChanged={onEmailChanged}
        {...rest}
        component={
          <ContactInfoFormInternal
            countryCode={countryCode}
            phone={phoneNumber}
            phoneError={phoneNumberError}
            onPhoneNumberChanged={onPhoneNumberChanged}
            email={email}
            emailError={emailError}
            onEmailChanged={onEmailChanged}
            {...rest}
          />
        }
      />
    </Box>
  );
};

export type ContactInfoFormInternalProps = Omit<
  IContactInfoFormProps,
  | "contactInfo"
  | "onContactInfoChange"
  | "validate"
  | "emailRegex"
  | "inHotel"
  | "phoneRegex"
  | "checkPhoneMinLength"
  | "minPhoneLength"
> & {
  countryCode: string;
  phone: string;
  phoneError: string;
  onPhoneNumberChanged: (
    _phoneNumber: string,
    _countryCode: string,
    customIsValid?: (number: string) => PhoneNumberValidatorEnum
  ) => void;
  email: string;
  emailError: string;
  onEmailChanged: (_email: string) => void;
  onFocusEmail?: (
    value: string,
    event?: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlurEmail?: (value: string) => void;
};

const ContactInfoFormInternal = ({
  // From IContactInfoFormProps
  title,
  subtitle,
  className,
  isMobile,
  disabled,
  hideEmailAddress = false,
  hidePhoneNumber = false,
  actions,
  headerVariant = "h3",
  header = true,
  showEditAction = false,
  onEdit,
  disableRipple,
  // From ContactInfoFormInternalProps
  countryCode,
  phone,
  phoneError,
  onPhoneNumberChanged,
  email,
  emailError,
  onEmailChanged,
  onFocusEmail,
  onBlurEmail,
}: ContactInfoFormInternalProps) => {
  const { t } = useI18nContext();
  return (
    <>
      {header ? (
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className="header-contact-info"
        >
          <Grid item className="contact-info-form-description">
            {title ? (
              <Typography
                className="title"
                variant={headerVariant ?? (isMobile ? "caption" : "h3")}
              >
                {title}
              </Typography>
            ) : null}
            {subtitle ? (
              <Typography variant="body2">{subtitle}</Typography>
            ) : null}
          </Grid>
          <Grid item xs />
          {showEditAction && (
            <Grid item>
              <Button
                className="edit-action"
                disableRipple={disableRipple}
                variant="text"
                color="primary"
                onClick={onEdit}
              >
                {t("edit")}
              </Button>
            </Grid>
          )}
        </Grid>
      ) : null}
      <Typography
        className={clsx("contact-info-form-required")}
        variant="body2"
      >
        {`${t("requiredField")}*`}
      </Typography>
      <Box className={clsx("contact-info-form", className)}>
        {!hidePhoneNumber && (
          <Grid item>
            <PhoneInputField
              label={`${t("phoneNumber")} *`}
              defaultValue={phone}
              countryCode={countryCode}
              errorHelper={phoneError}
              onChange={onPhoneNumberChanged}
              disabled={disabled}
            />
          </Grid>
        )}
        {!hideEmailAddress && (
          <Grid item>
            <B2BTextField
              id="contact-email-input-field"
              className="email-input-field"
              label={t("email")}
              required
              value={email}
              errorHelper={emailError}
              onChange={onEmailChanged}
              onFocus={onFocusEmail}
              onBlur={onBlurEmail}
              disabled={disabled}
              type="email"
            />
          </Grid>
        )}
        {actions && (
          <Grid item>
            <ActionLinks
              className="action-links-contact-info"
              actions={actions}
            />
          </Grid>
        )}
      </Box>
    </>
  );
};
