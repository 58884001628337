export enum DisruptionExerciseProgress {
  NotStarted = "NotStarted",
  LandingPage = "LandingPage",
  RebookSelected = "RebookSelected",
  RefundSelected = "RefundSelected",
  FlightSelect = "FlightSelect",
  FlightSearch = "FlightSearch",
  Review = "Review",
  ConfirmationPage = "ConfirmationPage",
  Pending = "Pending",
  Completed = "Completed",
  RefundReview = "RefundReview",
  RefundConfirmation = "RefundConfirmation",
  RebookReview = "RebookReview",
  RebookConfirmation = "RebookConfirmation",
  ContactInfo = "ContactInfo",
  Loading = "Loading",
  NotEligible = "NotEligible",
}

export interface DisruptionExerciseState {
  disruptionExerciseProgress: DisruptionExerciseProgress;
  token: string;
}

export const initialDisruptionExerciseState: DisruptionExerciseState = {
  disruptionExerciseProgress: DisruptionExerciseProgress.NotStarted,
  token: "",
};

export const SET_DISRUPTION_EXERCISE_PROGRESS =
  "SET_DISRUPTION_EXERCISE_PROGRESS";
export type SET_DISRUPTION_EXERCISE_PROGRESS =
  typeof SET_DISRUPTION_EXERCISE_PROGRESS;

export const RESET_DISRUPTION_EXERCISE_STATE =
  "RESET_DISRUPTION_EXERCISE_STATE";
export type RESET_DISRUPTION_EXERCISE_STATE =
  typeof RESET_DISRUPTION_EXERCISE_STATE;

export interface ISetDisruptionExerciseProgress {
  type: SET_DISRUPTION_EXERCISE_PROGRESS;
  disruptionExerciseProgress: DisruptionExerciseProgress;
}

export interface IResetDisruptionExerciseState {
  type: RESET_DISRUPTION_EXERCISE_STATE;
}

export const setDisruptionExerciseProgress = (
  disruptionExerciseProgress: DisruptionExerciseProgress
): ISetDisruptionExerciseProgress => ({
  type: SET_DISRUPTION_EXERCISE_PROGRESS,
  disruptionExerciseProgress,
});

export const SET_DISRUPTION_EXERCISE_TOKEN = "SET_DISRUPTION_EXERCISE_TOKEN";
export type SET_DISRUPTION_EXERCISE_TOKEN =
  typeof SET_DISRUPTION_EXERCISE_TOKEN;

export interface ISetDisruptionExerciseToken {
  type: SET_DISRUPTION_EXERCISE_TOKEN;
  token: string;
}

export const setDisruptionExerciseToken = (
  token: string
): ISetDisruptionExerciseToken => ({
  type: SET_DISRUPTION_EXERCISE_TOKEN,
  token,
});

export interface IResetDisruptionExerciseState {
  type: RESET_DISRUPTION_EXERCISE_STATE;
}

export const resetDisruptionExerciseState =
  (): IResetDisruptionExerciseState => ({
    type: RESET_DISRUPTION_EXERCISE_STATE,
  });

export type DisruptionActions =
  | ISetDisruptionExerciseProgress
  | IResetDisruptionExerciseState
  | ISetDisruptionExerciseToken;

export enum DisruptionContractStatus {
  REDEEM = "Redeem",
  ELIGIBLE = "Eligible",
  CONCLUDE = "Conclude",
  CANCEL = "Cancel",
  PURCHASE = "Purchase",
  // future-proof contract statuses
  ANY_EXERCISE_ELIGIBLE = "AnyExerciseEligible",
  EXERCISE_NOT_ELIGIBLE = "ExerciseNotEligible",
  REBOOK_ONLY_ELIGIBLE = "RebookOnlyEligible",
  ALREADY_EXERCISED = "AlreadyExercised",
}

export enum EventCovered {
  DELAYS = "Delays",
  MISSED_CONNECTIONS = "MissedConnections",
  CANCELLATIONS = "Cancellations",
}

export function disruptionExerciseReducer(
  state: DisruptionExerciseState = initialDisruptionExerciseState,
  action: DisruptionActions
): DisruptionExerciseState {
  switch (action.type) {
    case SET_DISRUPTION_EXERCISE_PROGRESS:
      return {
        ...state,
        disruptionExerciseProgress: action.disruptionExerciseProgress,
      };

    case RESET_DISRUPTION_EXERCISE_STATE:
      return {
        ...state,
        disruptionExerciseProgress: DisruptionExerciseProgress.NotStarted,
      };

    case SET_DISRUPTION_EXERCISE_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    default:
      return state;
  }
}
