import {
  Fare,
  FareDetailsV2,
  FareSliceOutbound,
  Flights,
  Tags,
  TripDetailsV2,
} from "@b2bportal/air-shopping-api";
import {
  AirlineCode,
  AlgomerchTag,
  FlightNumberOption,
  FlightRatingsEnum,
  FlightShopStep,
  IAirlineOptions,
  IAirportOptions,
  IFareData,
  IFareTrip,
  IFilterOptions,
  IFlightData,
  IFlightFares,
  IFlightListData,
  IFlightNumbersByAirlineCode,
  ISelectedTrip,
  ITripDetailsByTripId,
  ResponseWithStatus,
  SortOptionEnum,
  TripCategory,
} from "@b2bportal/core-types";
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  getAirlineFilter,
  getAirportFilter,
  getFareclassOptionFilter,
  getFlightNumberFilter,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetArrivalTimeRange,
  getHasSetDepartureTimeRange,
  getHasSetFareClassFilter,
  getHasSetFlightNumberFilter,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetStopsOption,
  getMaxPriceFilter,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getStopsOption,
  getStopsOptionFilter,
  passengerCountSelector,
} from "../../../search/store/selectors";

import * as filters from "./utils/processFilters";
import * as sorters from "./utils/processSort";

import {
  CfarFetchOfferRequest,
  ShopFilter,
  SliceStopCountFilter,
} from "@hopper-b2b/types";
import { uniq, uniqBy } from "lodash-es";
import { FlightState } from "../../../../store";
import {
  IFilterState,
  initialFilterOptions,
} from "../../../search/store/slice";
import { ensureExhaustive } from "@b2bportal/core-utilities";

export const getFlightsLoadingState = (state: FlightState) => {
  return state.flights.flightShop.flightsLoading;
};

export const getFlightsErrorState = (state: FlightState) => {
  return state.flights.flightShop.flightsError;
};

export const getFlightsData = (state: FlightState) => {
  return state.flights.flightShop.flights;
};

export const getPrediction = (state: FlightState) => {
  return state.flights.flightShop.prediction;
};

export const getPriceDropProtection = (state: FlightState) => {
  return state.flights.flightShop.prediction?.priceDropProtection;
};

export const getAirports = createSelector(
  (state: FlightState) => state.flights.flightShop.flights?.airports,
  (airports) => airports ?? {}
);

export const getSortOption = (state: FlightState) => {
  return state.flights.flightShop.sortOption;
};

export const tripDetailsByIdSelector = (
  state: FlightState
): ITripDetailsByTripId => {
  return state.flights.flightShop.tripDetailsById;
};

export const getSelectedTrip = (state: FlightState) => {
  return state.flights.flightShop.selectedTrip;
};

export const flightShopProgressSelector = (state: FlightState) =>
  state.flights.flightShop.shopStep;

export const getFlights = createSelector(
  getFlightsLoadingState,
  getFlightsErrorState,
  getFlightsData,
  (isLoading, isError, data): ResponseWithStatus<Flights> => {
    return {
      isLoading: isLoading ?? false,
      isError,
      data,
    };
  }
);

export const selectedTripSelector = (state: FlightState): ISelectedTrip =>
  state.flights.flightShop.selectedTrip;

export const getDepartureFlightList = createSelector(
  getFlights,
  (flightRes): IFlightListData<FareSliceOutbound>[] => {
    const { data: flights } = flightRes;
    return (
      flights?.outbound.map((f) => ({ sliceId: f.slice, fares: f.fares })) ?? []
    );
  }
);

export const airportsSelector = createSelector(getFlightsData, (flights) => {
  return flights?.airports;
});

export const airlinesSelector = createSelector(getFlightsData, (flights) => {
  return flights?.airlines;
});

export const sortOptionSelector = (state: FlightState): SortOptionEnum =>
  state.flights.flightShop.sortOption;

export const maxFlightPriceSelector = createSelector(
  getDepartureFlightList,
  (flightList): number => {
    return flightList.reduce(
      (max, flight: IFlightListData<FareSliceOutbound>) => {
        return flight.fares?.reduce((fareMax, fare: any) => {
          return Math.max(fare.amount.fiat?.value, fareMax);
        }, max);
      },
      0
    );
  }
);

export const hasSetMaxPriceFilterSelector = createSelector(
  getMaxPriceFilter,
  maxFlightPriceSelector,
  (maxPriceFilter, maxFlightPrice) => {
    return (
      maxPriceFilter !== initialFilterOptions.maxPriceFilter &&
      maxPriceFilter !== maxFlightPrice
    );
  }
);

export const getReturnFlightList = createSelector(
  getFlights,
  selectedTripSelector,
  (flightRes, selectedTrip): IFlightListData<Fare>[] => {
    if (selectedTrip.outgoingSliceId === null) return [];

    const getReturnFlights = (flights: Flights, returnTripIds: string[]) => {
      const selectedOutgoingFare = selectedTrip.outgoingFareId
        ? flights.fares[selectedTrip.outgoingFareId]
        : null;
      const selectedOutgoingFareSlice = selectedOutgoingFare
        ? flights.fareSlices[selectedOutgoingFare.outbound]
        : null;

      const selectedOutgoingFareSliceId = selectedOutgoingFareSlice?.id;

      return returnTripIds.reduce(
        (returnFlightList: IFlightListData<Fare>[], tripId: string) => {
          const trip = flights.trips[tripId];
          const returnSliceId = flights.trips[tripId].return || "";

          const tripFares = trip.fares.map(
            (fareId: string) => flights.fares[fareId]
          );

          // Logic for filtering return flights
          const getFilteredFares = () => {
            return tripFares.filter((tripFare) => {
              if (!tripFare.return) return true;

              const returnFareSlice = flights.fareSlices[tripFare.return];
              const outgoingFareSlice = flights.fareSlices[tripFare.outbound];

              // if the fare has the selected outgoing FARE SLICE, the return fare slice must be valid (even though the fare classes are different - Hacker fare)
              if (
                selectedOutgoingFareSliceId &&
                tripFare.outbound == selectedOutgoingFareSliceId
              ) {
                return true;
                // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                // as long as the matching outbound fare is higher or equal to the selected outboound
              } else if (
                selectedTrip.outgoingFareRating !== null &&
                selectedTrip.outgoingFareRating !== undefined
              ) {
                return (
                  returnFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating &&
                  outgoingFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating
                );
              } else {
                return false;
              }
            });
          };

          const filteredTripFares = getFilteredFares();

          if (filteredTripFares.length > 0) {
            returnFlightList.push({
              sliceId: returnSliceId,
              fares: filteredTripFares,
            });
          }

          return returnFlightList;
        },
        []
      );
    };

    const { data: flights } = flightRes;
    if (flights == null) return [];
    const sliceOutbound = flights.outbound.find(
      (flight) => flight.slice === selectedTrip.outgoingSliceId
    );

    //Fall back to the first fare next list if fare is not found.
    //This will happen if there was a refresh in flights object.
    //The fallback can be used as all fares next list are identical on the same outbound flight.
    const fare =
      sliceOutbound != null
        ? sliceOutbound.fares.find(
            (fare) => fare.example.fare === selectedTrip.outgoingFareId
          )
        : undefined;
    return fare != null && fare.next != null
      ? getReturnFlights(flights, fare.next)
      : [];
  }
);

export const getFlightData = createSelector(
  getFlights,
  (_, sliceId: string) => sliceId,
  (
    flightRes: ReturnType<typeof getFlights>,
    sliceId: string
  ): IFlightData | undefined => {
    const { data: flights } = flightRes;
    if (flights == null) return undefined;

    const slice = flights.slices[sliceId];

    const departureDateTime = slice.departure;
    const arrivalDateTime = slice.arrival;

    const durationDiff = dayjs.duration(slice.totalDurationMinutes, "minutes");
    const days = durationDiff.days();
    const hours = durationDiff.hours();
    const minutes = durationDiff.minutes();
    const duration = {
      days,
      hours,
      minutes,
    };

    const plusDays = slice.segments.reduce((totalPlusDays, segment) => {
      return totalPlusDays + segment.plusDays;
    }, 0);

    const airlineCode = slice.marketingAirline;
    const airlineName = flights.airlines[airlineCode].displayName;
    return {
      arrivalDateTime,
      departureDateTime,
      duration,
      originCode: slice.origin,
      destinationCode: slice.destination,
      flightStops: slice.stops,
      plusDays,
      airlineCode,
      airlineName,
    };
  }
);

const getFareSliceId = (fare: FareSliceOutbound | Fare): string => {
  if ("fareSlice" in fare) {
    return (fare as FareSliceOutbound).fareSlice || "";
  } else {
    return (fare as Fare).return || "";
  }
};

const getFareId = (fare: FareSliceOutbound | Fare): string => {
  if ("example" in fare) {
    return (fare as FareSliceOutbound).example?.fare || "";
  } else {
    return (fare as Fare).id || "";
  }
};

export function getTags(fareTags: Tags): AlgomerchTag[] {
  const tagMapping: Record<string, AlgomerchTag> = {
    isCheapest: AlgomerchTag.Cheapest,
    isFastest: AlgomerchTag.Fastest,
    isBest: AlgomerchTag.BestFlight,
    isBestQuality: AlgomerchTag.BestQuality,
  };

  return Object.entries(fareTags)
    .filter(([_, value]) => value)
    .map(([key]) => tagMapping[key]);
}

export const mapI18nAlgomerchText = {
  BestFlight: "algomerch.bestFlightTag.title",
  Cheapest: "algomerch.cheapestTag.title",
  BestQuality: "algomerch.bestQualityTag.title",
  Fastest: "algomerch.fastestTag.title",
};

export const getFareData = createSelector(
  getFlights,
  (_, fareArr: Array<Fare | FareSliceOutbound>) => ({
    fareArr,
  }),
  (
    flightRes: ReturnType<typeof getFlights>,
    { fareArr }: { fareArr: Array<Fare | FareSliceOutbound> }
  ): IFlightFares => {
    const { data: flights } = flightRes;
    if (flights == null) return {};

    return fareArr.reduce((acc, fare) => {
      const fareSliceId = getFareSliceId(fare);
      const fareSlice = flights.fareSlices[fareSliceId];
      const fareClass = FlightRatingsEnum[fareSlice.fareShelf.value];
      const fareShelf = fareSlice.fareShelf.value;
      // Check if the fare has fiat value
      if (fare.amount?.fiat.value != null) {
        const existingFare: IFareData | undefined = acc[fareClass];
        // Check if there is no existing fare or the new fare has a lower price

        if (!existingFare) {
          const tags = getTags(fareSlice?.tags);
          acc[fareClass] = {
            fareId: getFareId(fare),
            fareName: fareSlice?.fareBrandName ?? "",
            fareClass: fareClass,
            fareShelf,
            price: fare.amount,
            tags,
            numberOfFares: -1,
            segments: fareSlice.segments,
          };
        }
        if (acc[fareClass].price.fiat.value > fare.amount.fiat.value) {
          acc[fareClass].price = fare.amount;
        }
        acc[fareClass].numberOfFares = acc[fareClass].numberOfFares + 1;
      }

      return acc;
    }, {} as IFlightFares);
  }
);

export const getTripDetails = createSelector(
  tripDetailsByIdSelector,
  (_, fareTrips: IFareTrip[]) => fareTrips,
  (
    tripDetailsById: ReturnType<typeof tripDetailsByIdSelector>,
    fareTrips: IFareTrip[]
  ): ResponseWithStatus<TripDetailsV2> => {
    const fetchedAllFareDetails =
      fareTrips?.length > 0
        ? fareTrips?.reduce((hasFetchedFareTrip: boolean, fareTrip) => {
            return (
              hasFetchedFareTrip && tripDetailsById[fareTrip.tripId] != null
            );
          }, true)
        : false;

    if (fetchedAllFareDetails) {
      const data = fareTrips?.reduce(
        (acc: TripDetailsV2, fareTrip) => {
          const fareDetailByFareId = tripDetailsById[
            fareTrip.tripId
          ].fareDetails.find((fareDetail) => fareDetail.id === fareTrip.fareId);

          if (fareDetailByFareId) {
            acc.fareDetails.push(fareDetailByFareId);
          }

          return acc;
        },
        { ...tripDetailsById[fareTrips[0]?.tripId], fareDetails: [] }
      );
      return { isLoading: false, isError: false, data };
    } else {
      return {
        isLoading: true,
        isError: false,
        data: undefined,
      };
    }
  }
);

export const hasSelectedDepartureFlights = createSelector(
  getSelectedTrip,
  (selectedTrip) => {
    return (
      selectedTrip.tripId &&
      selectedTrip.outgoingSliceId &&
      selectedTrip.outgoingFareId
    );
  }
);

export const hasSelectedReturnFlights = createSelector(
  getSelectedTrip,
  (selectedTrip) => {
    return (
      selectedTrip.tripId &&
      selectedTrip.returnSliceId &&
      selectedTrip.returnFareId
    );
  }
);

export const allFiltersSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOption,
  getMaxPriceFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  (
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter
  ): IFilterState => ({
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
  })
);

export const filterFlights = <T extends Fare | FareSliceOutbound>(
  allFiltersSelector: IFilterState,

  hasSetStopsOption: boolean,
  hasSetDepartureTimeRange: boolean,
  hasSetArrivalTimeRange: boolean,
  hasSetAirlineFilter: boolean,
  hasSetAirportFilter: boolean,
  hasSetFlightNumberFilter: boolean,
  hasSetMaxPriceFilter: boolean,
  hasSetFareClassFilter: boolean,

  isInChooseReturnStep: boolean,
  flights: Flights | undefined,
  flightList
): IFlightListData<T>[] => {
  if (flights == null) return [];

  const {
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
    fareclassOptionFilter,
  } = allFiltersSelector;

  const isReturn = isInChooseReturnStep;

  const selectedFaresOptionsArray = Object.keys(fareclassOptionFilter).filter(
    (key) => fareclassOptionFilter[key] && key
  );

  const meetsFilterPredicates = (
    flight: IFlightListData<Fare | FareSliceOutbound>
  ) => {
    let meetsConditions = true;

    const flightSlice = flights.slices[flight.sliceId];
    if (hasSetStopsOption) {
      meetsConditions =
        meetsConditions &&
        filters.performStopOptionFilterV2(flightSlice, stopsOption);
    }

    if ((hasSetDepartureTimeRange || hasSetArrivalTimeRange) && !isReturn) {
      meetsConditions =
        meetsConditions &&
        filters.performTimeRangeFilterV2(
          flightSlice,
          outboundDepartureTimeRange,
          outboundArrivalTimeRange
        );
    }

    if ((hasSetDepartureTimeRange || hasSetArrivalTimeRange) && isReturn) {
      meetsConditions =
        meetsConditions &&
        filters.performTimeRangeFilterV2(
          flightSlice,
          returnDepartureTimeRange,
          returnArrivalTimeRange
        );
    }

    if (hasSetAirlineFilter) {
      meetsConditions =
        meetsConditions &&
        filters.performAirlineFilterV2(flightSlice, airlineFilter);
    }

    if (hasSetAirportFilter) {
      meetsConditions =
        meetsConditions &&
        filters.performAirportFilterV2(flightSlice, airportFilter);
    }

    if (hasSetFlightNumberFilter) {
      meetsConditions =
        meetsConditions &&
        filters.performFlightNumberFilterV2(flightSlice, flightNumberFilter);
    }

    if (hasSetMaxPriceFilter) {
      const flightFaresAmount = flight.fares.flatMap((fare) =>
        fare.amount != null ? { amount: fare.amount } : []
      );
      meetsConditions =
        meetsConditions &&
        filters.performMaxPriceFilterV2(flightFaresAmount, maxPriceFilter);
    }

    if (hasSetFareClassFilter) {
      meetsConditions =
        meetsConditions &&
        filters.performFareClassFilterV2(
          flights,
          flight,
          selectedFaresOptionsArray
        );
    }

    return meetsConditions;
  };

  return flightList.filter(meetsFilterPredicates);
};

export const getSortedFlightsList = <T extends Fare | FareSliceOutbound>(
  flightList: IFlightListData<T>[],
  step: FlightShopStep,
  flights: ReturnType<typeof getFlightsData>,
  sortOption: ReturnType<typeof sortOptionSelector>
): IFlightListData<T>[] => {
  if (flights == null) return [];
  switch (sortOption) {
    case "fareScore":
      return [
        ...sorters.orderByRecommendedV3(
          flightList,
          flights,
          step === FlightShopStep.return
        ),
      ];
    case "price":
      return [...sorters.orderByPriceV2(flightList)];
    case "departureTime":
      return sorters.orderByDepartureTimeV2(flightList, flights);
    case "arrivalTime":
      return [...sorters.orderByArrivalTimeV2(flightList, flights)];
    case "stops":
      return [...sorters.orderByStopsV2(flightList, flights)];
    case "duration":
      return [...sorters.orderByDurationV2(flightList, flights)];
    case "stopAndDepartureTime":
      return [...sorters.orderByStopsThenDeparture(flightList, flights)];

    default:
      return [
        ...sorters.orderByRecommendedV3(
          flightList,
          flights,
          step === FlightShopStep.return
        ),
      ];
  }
};

export const getFilteredAndSortedDepartureFlightList = createSelector(
  getDepartureFlightList,
  getFlightsData,
  sortOptionSelector,
  allFiltersSelector,
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,
  (
    departureList,
    flightData,
    sortOption,
    allFiltersSelector,

    hasSetStopsOption,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    hasSetMaxPriceFilter,
    hasSetFareClassFilter
  ): IFlightListData<FareSliceOutbound>[] => {
    const filteredFlights = filterFlights<FareSliceOutbound>(
      allFiltersSelector,
      hasSetStopsOption,
      hasSetDepartureTimeRange,
      hasSetArrivalTimeRange,
      hasSetAirlineFilter,
      hasSetAirportFilter,
      hasSetFlightNumberFilter,
      hasSetMaxPriceFilter,
      hasSetFareClassFilter,
      false,
      flightData,
      departureList
    );
    return getSortedFlightsList<FareSliceOutbound>(
      filteredFlights,
      FlightShopStep.departure,
      flightData,
      sortOption
    );
  }
);

export const getFilteredAndSortedReturnFlightList = createSelector(
  getReturnFlightList,
  getFlightsData,
  sortOptionSelector,
  allFiltersSelector,
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,
  (
    returnList,
    flightData,
    sortOption,
    allFiltersSelector,
    hasSetStopsOption,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    hasSetMaxPriceFilter,
    hasSetFareClassFilter
  ): IFlightListData<FareSliceOutbound>[] => {
    const filteredFlights = filterFlights<FareSliceOutbound>(
      allFiltersSelector,
      hasSetStopsOption,
      hasSetDepartureTimeRange,
      hasSetArrivalTimeRange,
      hasSetAirlineFilter,
      hasSetAirportFilter,
      hasSetFlightNumberFilter,
      hasSetMaxPriceFilter,
      hasSetFareClassFilter,
      true,
      flightData,
      returnList
    );
    return getSortedFlightsList<FareSliceOutbound>(
      filteredFlights,
      FlightShopStep.return,
      flightData,
      sortOption
    );
  }
);

export const getSelectedTripDetails = (state: FlightState) => {
  const tripCategory = state.flights.flightSearch.tripCategory;
  const selectedTrip = state.flights.flightShop.selectedTrip;

  if (selectedTrip.tripId == null) {
    return undefined;
  }

  const trip = (() => {
    switch (tripCategory) {
      case TripCategory.ONE_WAY:
        if (selectedTrip.outgoingFareId == null) {
          return null;
        }
        return {
          tripId: selectedTrip.tripId,
          fareId: selectedTrip.outgoingFareId,
        };
      case TripCategory.ROUND_TRIP:
        if (selectedTrip.returnFareId == null) {
          return null;
        }
        return {
          tripId: selectedTrip.tripId,
          fareId: selectedTrip.returnFareId,
        };
      default:
        return ensureExhaustive(tripCategory, null);
    }
  })();
  if (trip == null) {
    return undefined;
  }

  const { data: tripDetails } = getTripDetails(state, [trip]);

  return tripDetails;
};

export const getSelectedFarePaxPricing = createSelector(
  getSelectedTripDetails,
  (flightDetails) => {
    return flightDetails?.fareDetails[0].paxPricings?.find(
      (pax) => pax.paxType === "ADT"
    );
  }
);

export const shopPricingInfoSelector = createSelector(
  getSelectedTripDetails,
  (flightDetails) => {
    return { fare: flightDetails?.fareDetails[0].paxPricings };
  }
);

export const getOriginCountryCode = createSelector(
  getFlightsData,
  selectedTripSelector,
  (flights, selectedTrip) => {
    const airports = flights?.airports;
    const outgoingSlice = flights?.slices[selectedTrip.outgoingSliceId || ""];
    return (
      airports?.[outgoingSlice?.origin || ""]?.geography?.countryCode || ""
    );
  }
);

export const getDestinationCountryCode = createSelector(
  getFlightsData,
  selectedTripSelector,
  (flights, selectedTrip) => {
    const airports = flights?.airports;
    const outgoingSlice = flights?.slices[selectedTrip.outgoingSliceId || ""];
    return (
      airports?.[outgoingSlice?.destination || ""]?.geography?.countryCode || ""
    );
  }
);

export const getSelectedTripDetailsAndFareDetails = createSelector(
  selectedTripSelector,
  tripDetailsByIdSelector,
  (
    selectedTrip,
    tripDetailsById
  ): { tripDetails: TripDetailsV2; fareDetails?: FareDetailsV2 } => {
    const tripDetails = tripDetailsById[selectedTrip.tripId ?? ""];

    const fareId =
      selectedTrip.returnFareId != null
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId;
    const fareDetails = tripDetailsById[
      selectedTrip.tripId ?? ""
    ]?.fareDetails.find((f) => f.id === fareId);
    return { tripDetails, fareDetails };
  }
);

export const isOutgoingMultiTicket = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    if (trip && selectedTrip.outgoingFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedTrip.outgoingFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const isReturnMultiTicket = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    if (trip && selectedTrip.returnFareId) {
      return !!trip.fareDetails.find((f) => f.id === selectedTrip.returnFareId)
        ?.multiTicket;
    }
    return false;
  }
);

export const isHackerFare = createSelector(getSelectedTripDetails, (trip) => {
  if (!trip) return false;

  const tripSliceDeparture = trip.slices[0];
  const tripSliceReturn = trip.slices[1];

  const airlineDeparture =
    tripSliceDeparture.segmentDetails[0]?.marketingAirline?.code;
  const airlineReturn =
    tripSliceReturn?.segmentDetails[0]?.marketingAirline?.code;

  return (
    airlineDeparture !== undefined &&
    airlineReturn !== undefined &&
    airlineDeparture !== airlineReturn
  );
});

const getFilterOptions = <T extends Fare | FareSliceOutbound>(
  flights: Flights | undefined,
  flightList: IFlightListData<T>[]
): IFilterOptions => {
  const airlines = new Set<AirlineCode>();
  const allAirlines: IAirlineOptions[] = [];
  const allAirports: IAirportOptions[] = [];
  const flightNumberOptions: FlightNumberOption[] = [];
  const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};

  const flightShopFilters: IFilterOptions = {
    airlineOptions: allAirlines,
    airportOptions: allAirports,
    flightNumberOptions,
    flightNumbersByAirline,
    priceMin: undefined,
    priceMax: undefined,
  };

  if (flights == null) return flightShopFilters;

  flightList.forEach((flight: IFlightListData<T>) => {
    const flightSlice = flights.slices[flight.sliceId];
    // AIRLINES
    const airlineCode = flightSlice.marketingAirline;
    if (!airlines.has(airlineCode)) {
      allAirlines.push({
        value: airlineCode,
        label: flights?.airlines[airlineCode]?.displayName || "",
      });
    }
    airlines.add(airlineCode);

    //AIRPORTS
    allAirports.push({
      value: flightSlice.origin,
      label: flights.airports[flightSlice.origin].name,
    });
    allAirports.push({
      value: flightSlice.destination,
      label: flights.airports[flightSlice.destination].name,
    });

    //PRICES
    for (const fare of flight.fares) {
      const fiat = fare.amount?.fiat;
      if (fiat == null) continue;

      if (
        flightShopFilters.priceMin == null ||
        fiat.value < flightShopFilters.priceMin.value
      ) {
        flightShopFilters.priceMin = { ...fiat };
      }

      if (
        flightShopFilters.priceMax == null ||
        fiat.value > flightShopFilters.priceMax.value
      ) {
        flightShopFilters.priceMax = { ...fiat };
      }
    }

    //FLIGHT NUMBER
    const [{ flightNumber }] = flightSlice.segments;
    flightNumberOptions.push({
      value: {
        airlineCode,
        flightNumber,
      },
      label: `${airlineCode} ${flightNumber}`,
    });

    if (flightNumbersByAirline[airlineCode] == null) {
      flightNumbersByAirline[airlineCode] = [flightNumber];
    } else {
      flightNumbersByAirline[airlineCode] = uniq([
        ...flightNumbersByAirline[airlineCode],
        flightNumber,
      ]);
    }
  });

  flightShopFilters.flightNumberOptions = uniqBy(flightNumberOptions, "label");
  flightShopFilters.airportOptions = uniqBy(allAirports, "value");

  return flightShopFilters;
};

export const getDepartureFiltersOptions = createSelector(
  getFlightsData,
  getDepartureFlightList,
  (flights, flightList): IFilterOptions => {
    return getFilterOptions(flights, flightList);
  }
);

export const getReturnFiltersOptions = createSelector(
  getFlightsData,
  getReturnFlightList,
  (flights, flightList): IFilterOptions => {
    return getFilterOptions(flights, flightList);
  }
);

export const hasSetNonFareclassFiltersSelector = createSelector(
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

export const hasSetFiltersSelector = createSelector(
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

export const getNumberOfPassnegers = createSelector(
  passengerCountSelector,
  (passengerCount) => {
    return Object.values(passengerCount).reduce((a, b) => a + b, 0);
  }
);

export const shopFilterSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOptionFilter,
  (fareclassOptionFilter, stopsOption): ShopFilter => {
    let tripFilter: ShopFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    return tripFilter;
  }
);

export const getCfarOffersRequestParams = createSelector(
  shopFilterSelector,
  selectedTripSelector,
  passengerCountSelector,
  (shopFilter, selectedTrip, passengerCount): CfarFetchOfferRequest | null => {
    const tripId = selectedTrip.tripId;
    const fareId =
      selectedTrip.returnFareId != null
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId;

    if (tripId != null && tripId !== "" && fareId != null && fareId !== "") {
      return {
        tripId,
        fareId,
        tripFilter: shopFilter,
        passengers: { ...passengerCount } as { [key in string]: number },
      };
    } else {
      return null;
    }
  }
);
