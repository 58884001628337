/*
 * @param _value The value to check for exhaustiveness.
 */
export function ensureExhaustive(_value: never): never;
export function ensureExhaustive<T>(_value: never, defaultValue: T): T;
export function ensureExhaustive<T>(_value: never, defaultValue?: T): T {
  console.warn(
    "There is a type not being handled properly, please view the stack trace for more info."
  );
  return defaultValue as T;
}
