export enum CFARIconNameEnum {
  cancelForAnyReason = "cancelForAnyReason",
  informational = "informational",
  informationalBrand = "informationalBrand",
  clockFace = "clockFace",
  dollar = "dollar",
  importantInformation1 = "importantInformation1",
  importantInformation2 = "importantInformation2",
  importantInformation3 = "importantInformation3",
  tenantLogo = "tenantLogo",
  checkBulletPoint = "checkBulletPoint",
}
