import clsx from "clsx";
import { Suggestion } from "@b2bportal/lodging-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  URL_PARAM_KEYS,
  buildSearchParams,
  placeLabelAndSublabelToUrl,
} from "@hopper-b2b/lodging-utils";
import {
  GuestsSelection,
  LodgingShopTrackingEvents,
  TripCategory,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CalendarPicker } from "./components/CalendarPickerButton/CalendarPicker";
import { GuestPicker } from "./components/GuestPicker";
import { HotelLocationAutoComplete } from "./components/HotelLocationAutocomplete";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import { PATH_HOTELS_BOOK_AVAILABILITY } from "@hopper-b2b/utilities";

export type HotelSearchFormHandlers = {
  onDestinationChange?: (destination: Suggestion) => void;
  onCheckingDateChange?: (date: Date) => void;
  onCheckoutDateChange?: (date: Date | undefined) => void;
  onGuestCountChange?: (guestCount: GuestsSelection) => void;
};
export type HotelSearchProps = {
  changeHandlers?: HotelSearchFormHandlers;
  enableRooms?: boolean;
  initialAdultGuests?: number;
  initialChildGuests?: number[];
  initialRooms?: number;
  initialLocation?: Suggestion;
  initialCheckinDate?: string;
  initialCheckoutDate?: string;
  onSearch?: (path: string) => void;
  onOpen?: () => void;
  LocationAndGuestScreenHeader?: React.ComponentType<{
    onBack: () => void;
  }>;
  CalendarScreenHeader?: React.ComponentType<{
    onBack: () => void;
  }>;
  className?: string;
  openCalendarModal?: boolean;
  modal?: boolean;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
};

enum LodgingSearchStep {
  LocationAndGuestCountScreen = "LocationAndGuestCountScreen",
  CalendarPickerScreen = "CalendarPickerScreen",
}

/***
 * @deprecated
 * This component is only used in Nubank portal and only supports Mobile view.
 * Use `HotelSearch` component instead.
 * Context: https://github.com/hopper-org/b2bportal/pull/3426#discussion_r1518124103
 */
export const LodgingSearchRoot = ({
  changeHandlers,
  enableRooms: enableRoomsProps = true,
  initialAdultGuests = 2,
  initialChildGuests = [],
  initialRooms = 1,
  initialLocation,
  onSearch,
  onOpen,
  initialCheckinDate,
  initialCheckoutDate,
  LocationAndGuestScreenHeader,
  CalendarScreenHeader,
  className,
  openCalendarModal = false,
  modal = false,
  minAllowedDate,
  maxAllowedDate,
}: HotelSearchProps) => {
  // TODO: Figure out if we need this flag
  // const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps; /* ?? enableRoomsFlag */

  const [searchStep, setSearchStep] = useState<LodgingSearchStep>(
    openCalendarModal
      ? LodgingSearchStep.CalendarPickerScreen
      : LodgingSearchStep.LocationAndGuestCountScreen
  );
  const [destination, setDestination] = useState<Suggestion | undefined>(
    initialLocation
  );
  const [checkinDate, setCheckinDate] = useState<string | undefined>(
    initialCheckinDate
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    initialCheckoutDate
  );
  const [guestCount, setGuestCount] = useState<GuestsSelection>({
    adults: initialAdultGuests,
    children: initialChildGuests,
    rooms: initialRooms,
  });

  const [defaultSearchParams] = useSearchParams();

  useEffect(() => {
    setDestination(initialLocation);
  }, [initialLocation]);

  const firstLoad = sessionStorage.getItem("firstLoad");

  useEffect(() => {
    if (!firstLoad || firstLoad !== "true") {
      trackEvent({
        eventName: LodgingShopTrackingEvents.hotel_entry,
        properties: {
          landing_screen: "hotel_search",
        },
      });
      sessionStorage.setItem("firstLoad", "true");
    }
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_viewed_search,
      properties: {},
    });
  }, [firstLoad]);

  const destinationCode = useMemo(() => {
    if (destination) {
      const { label, subLabel } = destination;
      return placeLabelAndSublabelToUrl(label, subLabel);
    }
    return undefined;
  }, [destination]);

  const isSearchReady = useMemo(
    () => !!destination && !!checkinDate && !!checkoutDate,
    [checkinDate, checkoutDate, destination]
  );

  const searchUrl = useMemo(() => {
    const defaultLatLng = defaultSearchParams.get(URL_PARAM_KEYS.LAT_LNG);
    const searchParams = buildSearchParams({
      [URL_PARAM_KEYS.FROM_DATE]: checkinDate,
      [URL_PARAM_KEYS.UNTIL_DATE]: checkoutDate,
      [URL_PARAM_KEYS.ADULTS_COUNT]: guestCount.adults,
      [URL_PARAM_KEYS.CHILDREN_COUNT]: guestCount.children,
      [URL_PARAM_KEYS.ROOMS_COUNT]: guestCount.rooms,
      [URL_PARAM_KEYS.LAT_LNG]: defaultLatLng,
      enableRooms,
    });

    return `${PATH_HOTELS_BOOK_AVAILABILITY}${destinationCode}?${searchParams.toString()}`;
  }, [
    defaultSearchParams,
    checkinDate,
    checkoutDate,
    guestCount.adults,
    guestCount.children,
    guestCount.rooms,
    enableRooms,
    destinationCode,
  ]);

  const handleSearch = useCallback(() => {
    onSearch ? onSearch?.(searchUrl) : (window.location.href = searchUrl);
  }, [onSearch, searchUrl]);

  return (
    <>
      {searchStep === LodgingSearchStep.LocationAndGuestCountScreen ? (
        <LocationAndGuestCountScreen
          guestCount={guestCount}
          setGuestCount={setGuestCount}
          onOpen={onOpen}
          initialLocation={initialLocation}
          changeHandlers={changeHandlers}
          setDestination={setDestination}
          destination={destination}
          setSearchStep={setSearchStep}
          LocationAndGuestScreenHeader={LocationAndGuestScreenHeader}
          className={className}
        />
      ) : null}
      {searchStep === LodgingSearchStep.CalendarPickerScreen ? (
        <CalendarPickerScreen
          handleSearch={handleSearch}
          isSearchReady={isSearchReady}
          guestCount={guestCount}
          changeHandlers={changeHandlers}
          setCheckinDate={setCheckinDate}
          setCheckoutDate={setCheckoutDate}
          checkinDate={checkinDate}
          checkoutDate={checkoutDate}
          CalendarScreenHeader={CalendarScreenHeader}
          setSearchStep={setSearchStep}
          className={className}
          openCalendarModal={openCalendarModal}
          modal={modal}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
        />
      ) : null}
    </>
  );
};

const LocationAndGuestCountScreen = ({
  destination,
  guestCount,
  setGuestCount,
  onOpen,
  initialLocation,
  changeHandlers,
  setDestination,
  setSearchStep,
  LocationAndGuestScreenHeader,
  className,
}: {
  destination?: Suggestion;
  guestCount: GuestsSelection;
  setGuestCount: React.Dispatch<React.SetStateAction<GuestsSelection>>;
  onOpen?: () => void;
  initialLocation?: Suggestion;
  changeHandlers: HotelSearchFormHandlers;
  setDestination: React.Dispatch<React.SetStateAction<Suggestion>>;
  setSearchStep: React.Dispatch<React.SetStateAction<LodgingSearchStep>>;
  LocationAndGuestScreenHeader: React.ComponentType<{
    onBack: () => void;
  }>;
  className?: string;
}) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();

  const handleDestinationChange = useCallback(
    (dest: Suggestion) => {
      setDestination(dest);

      changeHandlers?.onDestinationChange?.(dest);
    },
    [changeHandlers]
  );

  const updateGuestCount = useCallback(
    (guestCount: GuestsSelection) => {
      setGuestCount(guestCount);

      changeHandlers?.onGuestCountChange?.(guestCount);
    },
    [changeHandlers]
  );

  const onBack = useCallback(() => {
    sessionStorage.setItem("firstLoad", "false");
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {LocationAndGuestScreenHeader ? (
        <LocationAndGuestScreenHeader onBack={onBack} />
      ) : null}
      <div>
        <GuestPicker
          initialGuestCount={guestCount}
          updateGuestCount={updateGuestCount}
          enableRooms
        />
        <HotelLocationAutoComplete
          id="destination"
          label={t("whereAreYouStaying")}
          onChange={handleDestinationChange}
          onOpen={onOpen}
          defaultValue={initialLocation}
          className={clsx("lodging-destination", className)}
          value={destination}
          shrinkLabel={false}
        />
        <MobileFloatingButton
          className="lodging-search-button"
          disabled={!destination}
          onClick={() => setSearchStep(LodgingSearchStep.CalendarPickerScreen)}
        >
          {t("searchButton")}
        </MobileFloatingButton>
      </div>
    </>
  );
};

const CalendarPickerScreen = ({
  checkinDate,
  checkoutDate,
  handleSearch,
  guestCount,
  destination,
  setCheckinDate,
  changeHandlers,
  setCheckoutDate,
  CalendarScreenHeader,
  setSearchStep,
  className,
  modal = false,
  minAllowedDate,
  maxAllowedDate,
}: {
  checkinDate?: string;
  checkoutDate?: string;
  handleSearch: () => void;
  guestCount: GuestsSelection;
  destination?: Suggestion;
  setCheckinDate: React.Dispatch<React.SetStateAction<string>>;
  setCheckoutDate: React.Dispatch<React.SetStateAction<string>>;
  changeHandlers: HotelSearchFormHandlers;
  CalendarScreenHeader?: React.ComponentType<{
    onBack: () => void;
  }>;
  setSearchStep: React.Dispatch<React.SetStateAction<LodgingSearchStep>>;
  className?: string;
  modal?: boolean;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
}) => {
  const { t } = useI18nContext();

  const getTrackingProperties = useCallback(
    ({
      checkin = checkinDate,
      checkout = checkoutDate,
      guest = guestCount,
      dest = destination,
    }) => ({
      ...{
        properties: {
          ...dest?.trackingPropertiesV2.properties,
          ...{
            adults_count: guest.adults,
            children_count: guest.children.length,
            check_in_date: dayjs(checkin).format("YYYY-MM-DD"),
            check_out_date: dayjs(checkout).isValid()
              ? dayjs(checkout).format("YYYY-MM-DD")
              : null,
            advance: dayjs(checkin).diff(dayjs(), "day") || null,
            los: dayjs(checkout).diff(dayjs(checkin), "day") || null,
          },
        },
        encryptedProperties: dest?.trackingPropertiesV2?.encryptedProperties
          ? [dest.trackingPropertiesV2.encryptedProperties]
          : [],
      },
    }),
    [checkinDate, checkoutDate, guestCount, destination]
  );

  const handleCheckinDateChange = useCallback(
    (date: Date) => {
      if (date) {
        setCheckinDate(date.toString());
        setCheckoutDate(undefined);
        changeHandlers?.onCheckoutDateChange?.(undefined);
        changeHandlers?.onCheckingDateChange?.(date);
        trackEvent({
          eventName: LodgingShopTrackingEvents.hotel_selected_calendar_date,
          ...getTrackingProperties({
            checkin: date.toString(),
            checkout: null,
          }),
        });
      }
    },
    [changeHandlers, getTrackingProperties, setCheckinDate, setCheckoutDate]
  );

  const handleCheckoutDateChange = useCallback(
    (date: Date) => {
      if (date) {
        setCheckoutDate(date.toString());
        changeHandlers?.onCheckoutDateChange?.(date);
        trackEvent({
          eventName: LodgingShopTrackingEvents.hotel_selected_calendar_date,
          ...getTrackingProperties({ checkout: date.toString() }),
        });
      }
    },
    [changeHandlers, getTrackingProperties, setCheckoutDate]
  );

  useEffect(() => {
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_viewed_calendar,
      ...getTrackingProperties({}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onComplete = useCallback(() => {
    trackEvent({
      eventName: LodgingShopTrackingEvents.hotel_tapped_select_dates,
      ...getTrackingProperties({}),
    });
    handleSearch();
  }, [getTrackingProperties, handleSearch]);

  return (
    <>
      {CalendarScreenHeader ? (
        <CalendarScreenHeader
          onBack={() =>
            setSearchStep(LodgingSearchStep.LocationAndGuestCountScreen)
          }
        />
      ) : null}
      <CalendarPicker
        isMobile
        open
        departureDate={checkinDate ? dayjs(checkinDate).toDate() : null}
        returnDate={checkoutDate ? dayjs(checkoutDate).toDate() : null}
        tripCategory={TripCategory.ROUND_TRIP}
        setDepartureDate={handleCheckinDateChange}
        setReturnDate={handleCheckoutDateChange}
        startDateLabel={t("checkin") ?? undefined}
        endDateLabel={t("checkout") ?? undefined}
        headerTitle={t("hotelCalendarHeaderTitle")}
        onComplete={onComplete}
        disabled={false}
        paperClassName={className}
        modal={modal}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
      />
    </>
  );
};
