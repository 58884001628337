import { useState } from "react";
import { debounce } from "lodash-es";
import {
  tenantFlagsEnabled,
  useApiConfigSelector,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SliderDropdown } from "@hopper-b2b/ui";
import { trackEvent } from "@hopper-b2b/api";
import {
  APPLIED_FILTER,
  AppliedFilterTrackingProperties,
  FiatPrice,
} from "@hopper-b2b/types";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";
import { MaxPriceFilterSelectionProps } from "./container";

interface IMaxPriceFilterSelectionProps extends MaxPriceFilterSelectionProps {
  showDropdownContentOnly?: boolean;
  maximumPrice: Omit<FiatPrice, "currencySymbol">;
  minimumPrice: Omit<FiatPrice, "currencySymbol">;
  trackingProperties?: AppliedFilterTrackingProperties;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    maximumPrice,
    minimumPrice,
    showDropdownContentOnly,
    trackingProperties,
  } = props;
  const { t, formatFiatCurrency } = useI18nContext();
  const { postEvent } = useUberBridge();
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  const onValueChange = (maxPriceFilter: number) => {
    postEvent(APPLIED_FILTER, trackingProperties);
    trackEvent(generateTrackingEvent("price"), apiConfig);
    setMaxPriceFilter(maxPriceFilter);
  };

  const debouncedAction = debounce(onValueChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(newValue);
  };

  return (
    <SliderDropdown
      dropdownLabel={t("searchFilter.price")}
      sliderHeader={t("searchFilter.maxPrice")}
      showPriceStationary={
        tenantFlagsEnabled.FlightShopShowFilterPriceStationary
      }
      maxValue={maximumPrice.value}
      minValue={
        maximumPrice.value === minimumPrice.value ? 0 : minimumPrice.value
      }
      value={maxPriceFilter}
      setValue={handleChange}
      sliderLabelFormat={(value) =>
        formatFiatCurrency(
          { ...maximumPrice, value },
          {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }
        )
      }
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
