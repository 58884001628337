import { default as dayjs, Dayjs } from "dayjs";
import { URL_PARAM_KEYS } from "./constants";

export const URL_DATE_FORMAT = "YYYY-MM-DD" as const;

type BuildSearchParamsArgs = {
  [URL_PARAM_KEYS.FROM_DATE]?: string;
  [URL_PARAM_KEYS.UNTIL_DATE]?: string;
  [URL_PARAM_KEYS.ADULTS_COUNT]?: number;
  [URL_PARAM_KEYS.CHILDREN_COUNT]?: number[];
  [URL_PARAM_KEYS.ROOMS_COUNT]?: number;
  [URL_PARAM_KEYS.LAT_LNG]?: string;
  enableRooms?: boolean;
};

function defined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const buildSearchParams = ({
  fromDate,
  untilDate,
  adultsCount = 0,
  childrenCount = [],
  roomsCount = 0,
  enableRooms = false,
  latlng = null,
}: BuildSearchParamsArgs) => {
  const searchParams = new URLSearchParams(
    [
      [URL_PARAM_KEYS.FROM_DATE, formatDateForUrl(fromDate)],
      [URL_PARAM_KEYS.UNTIL_DATE, formatDateForUrl(untilDate)],
      adultsCount > 0 ? [URL_PARAM_KEYS.ADULTS_COUNT, `${adultsCount}`] : null,
      childrenCount?.length > 0
        ? [URL_PARAM_KEYS.CHILDREN_COUNT, `${childrenCount.length}`]
        : null,
      ...(childrenCount?.length > 0
        ? childrenCount.map((age) => [URL_PARAM_KEYS.CHILDREN_AGES, `${age}`])
        : []),
      enableRooms && roomsCount > 0
        ? [URL_PARAM_KEYS.ROOMS_COUNT, `${roomsCount}`]
        : null,
      [URL_PARAM_KEYS.LAT_LNG, latlng],
    ].filter(defined)
  );
  return searchParams;
};

export const formatDateForUrl = (
  dateString?: string | Dayjs
): (string & WithFormat<typeof URL_DATE_FORMAT>) | "" =>
  dateString
    ? (dayjs(dateString).format(URL_DATE_FORMAT) as string &
        WithFormat<typeof URL_DATE_FORMAT>)
    : "";

export const unifyChildrenAges = (
  childrenAges: number[],
  childrenCount: number
): number[] => {
  return childrenCount > 0
    ? new Array(childrenCount)
        .fill(1)
        .map((_, index) =>
          Number.isInteger(childrenAges[index]) && childrenAges[index] >= 0
            ? childrenAges[index]
            : 1
        )
    : [];
};

export interface WithFormat<TFormat extends string> {
  __format: TFormat;
}

function initPriceRange(urlMin: number, urlMax: number) {
  const min = isNaN(urlMin) ? 0 : urlMin;
  const max = isNaN(urlMax) || urlMax === 0 ? Infinity : urlMax;
  return {
    min,
    max,
    lowest: 0,
    highest: Infinity,
  };
}

export enum SortOption {
  MOST_RECOMMENDED = "mostRecommended",
  STAR_RATING = "starRating",
  USER_RATING = "userRating",
  PRICE = "price",
}

export enum ViewOption {
  LIST = "list",
  MAP = "map",
}

export function isValidSort(sort: unknown): sort is SortOption {
  return Object.values(SortOption).includes(sort as SortOption);
}

export function isValidView(sort: unknown): sort is ViewOption {
  return Object.values(ViewOption).includes(sort as ViewOption);
}

export const parseLodgingParams = (
  searchParams: URLSearchParams
): {
  adults: number;
  children: number[];
  rooms: number;
  fromDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  untilDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  map:
    | {
        centroid: { lat: number; lng: number } | undefined;
        zoom: number;
      }
    | undefined;
  lodgingIds: string[];
  filters: {
    starRating: number[];
    userRating: number;
    amenities: string[];
    filterAmenitiesAnd: boolean;
    freeCancellation: boolean;
    priceRange: { min: number; max: number; lowest: number; highest: number };
  };
  sortBy: SortOption;
  view: ViewOption;
} => {
  const count = Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT)) || 0;
  const ages = searchParams.getAll(URL_PARAM_KEYS.CHILDREN_AGES).map(Number);
  const children = unifyChildrenAges(ages, count);
  const latlng =
    searchParams
      .get(URL_PARAM_KEYS.LAT_LNG)
      ?.split(",")
      .map(Number)
      .filter((_) => !Number.isNaN(_)) || [];
  const zoom = Number(searchParams.get(URL_PARAM_KEYS.ZOOM)) || 13;

  const filters = {
    starRating:
      searchParams.get(URL_PARAM_KEYS.STAR_RATING)?.split(",").map(Number) ||
      [],
    userRating: Number(searchParams.get(URL_PARAM_KEYS.USER_RATING)) || 0,
    amenities: searchParams.get(URL_PARAM_KEYS.AMENITIES)?.split(",") || [],
    filterAmenitiesAnd:
      searchParams.get(URL_PARAM_KEYS.FILTER_AMENITIES_AND) === null,
    freeCancellation:
      searchParams.get(URL_PARAM_KEYS.FREE_CANCELLATION) !== null,
    priceRange: initPriceRange(
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MIN)),
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MAX))
    ),
  };

  const sortBy = searchParams.get(URL_PARAM_KEYS.SORT_BY);

  const view = searchParams.get(URL_PARAM_KEYS.VIEW);

  return {
    adults: Number(searchParams.get(URL_PARAM_KEYS.ADULTS_COUNT)) || 2,
    children,
    rooms: Number(searchParams.get(URL_PARAM_KEYS.ROOMS_COUNT)) || 1,
    fromDate: formatDateForUrl(searchParams.get(URL_PARAM_KEYS.FROM_DATE)),
    untilDate: formatDateForUrl(searchParams.get(URL_PARAM_KEYS.UNTIL_DATE)),
    map: {
      centroid:
        latlng.length > 1
          ? {
              lat: latlng[0] || 0,
              lng: latlng[1] || 0,
            }
          : undefined,
      zoom: zoom,
    },
    lodgingIds:
      searchParams
        .get(URL_PARAM_KEYS.LODGING_IDS)
        ?.split(",")
        .filter(Boolean) || [],
    filters,
    sortBy: isValidSort(sortBy) ? sortBy : SortOption.MOST_RECOMMENDED,
    view: isValidView(view) ? (view as ViewOption) : ViewOption.LIST,
  };
};
