import {
  DisruptionRefundFulfillRequest,
  DisruptionRefundFulfillResponseEnum,
  DisruptionRefundFulfillPollResponseEnum,
  airDisruptionApi,
  DisruptionRefundFulfillResponse,
} from "@b2bportal/air-disruption-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const disruptionRefundPoll = async (
  req: { value: string },
  index = 0
): Promise<any> => {
  const delayTimes = [0, 2000, 4000, 6000, 8000, 10000, 20000, 30000];
  await new Promise((resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 7)])
  );
  const response = await airDisruptionApi(
    axios
  ).apiV0DisruptionExerciseRefundFulfillPollPost(req);
  // eslint-disable-next-line no-useless-catch
  try {
    if (
      response.data.DisruptionRefundFulfillPollResponse ===
      DisruptionRefundFulfillPollResponseEnum.Pending
    ) {
      return disruptionRefundPoll(req, index++);
    } else if (
      response.data.DisruptionRefundFulfillPollResponse ===
      DisruptionRefundFulfillPollResponseEnum.RefundFulfillPollSuccess
    ) {
      return response;
    } else {
      throw new Error(
        "disruptionRefundPoll: " +
          response.data.DisruptionRefundFulfillPollResponse
      );
    }
  } catch (e) {
    throw e;
  }
};

export const disruptionRefundFulfill = createAsyncThunk<
  DisruptionRefundFulfillResponse | undefined,
  DisruptionRefundFulfillRequest
>(
  "disruption/exercise/refund/fulfill",
  async (request: DisruptionRefundFulfillRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRefundFulfillPost(request);
      if (
        res.data.DisruptionRefundFulfillResponse ===
        DisruptionRefundFulfillResponseEnum.RefundFulfillSuccess
      ) {
        const pollReq = { value: res.data.token.value, index: 0 };
        return disruptionRefundPoll(pollReq);
      } else {
        throw new Error(
          `disruptionRefundFulfill: ${res.data.DisruptionRefundFulfillResponse} ${res.data.reason}`
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
