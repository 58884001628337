import { Link } from "react-router-dom-v5-compat";
import { AirCfarExerciseFailureEnum } from "@b2bportal/air-cfar-api";
import {
  CfarExerciseProgress,
  IOpenModal,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import { I18nMarkup, Trans } from "@hopper-b2b/i18n";
import { ArrowActionLink } from "@hopper-b2b/ui";

import {
  setOpenModal,
  resetCfarExerciseState,
} from "../../../../pages/TripsList/actions/actions";
export interface ICfarActionButtonProps {
  text: string;
  actions?: (() => any)[];
  nonActions?: (() => void)[];
}

export type ICfarExerciseErrorContentSubtitle = (string | JSX.Element)[];

export interface ICfarExerciseErrorContent {
  title: string;
  subtitle: ICfarExerciseErrorContentSubtitle;
  primaryButton?: ICfarActionButtonProps;
  secondaryButton?: ICfarActionButtonProps;
}

export const getCfarExerciseErrorContent = ({
  cfarExerciseError,
  openModal,
  i18nContext,
  generalSupportChat,
  cfarExerciseProgress,
  onClose,
  onOpenFlightCancellation,
}: {
  cfarExerciseError: AirCfarExerciseFailureEnum;
  openModal: IOpenModal;
  i18nContext: any;
  generalSupportChat: () => void;
  cfarExerciseProgress: CfarExerciseProgress;
  onClose?: () => void;
  onOpenFlightCancellation?: () => void;
}): ICfarExerciseErrorContent | null => {
  let title = undefined;
  let subtitle: ICfarExerciseErrorContentSubtitle = [];
  let primaryButton: ICfarActionButtonProps | undefined = undefined;
  let secondaryButton: ICfarActionButtonProps | undefined = undefined;
  const closeActions = [
    () => setOpenModal({ type: null, selectedItinerary: null }),
    () => resetCfarExerciseState(),
  ];
  if (cfarExerciseError) {
    if (cfarExerciseError === AirCfarExerciseFailureEnum.Void) {
      const voidAction =
        onOpenFlightCancellation ??
        (() =>
          setOpenModal({
            type: MyTripsModalTypes.SelfServeCancelFlight,
            selectedItinerary: openModal.selectedItinerary,
          }));

      title = i18nContext("cfarExercise.cancelFlightTitle");
      subtitle = [
        <Trans i18nKey="cfarExercise.cancelBookingVoid" components={[<b />]} />,
        <Link to="/terms" target="_blank">
          <ArrowActionLink
            message={i18nContext("cfarExercise.termsAndConditionsVerbose")}
            showArrow
          />
        </Link>,
      ];
      primaryButton = {
        text: i18nContext("cfarExercise.cta.continue"),
        actions: [voidAction],
        nonActions: [...(onClose ? [onClose] : [])],
      };
      secondaryButton = {
        text: i18nContext("cfarExercise.cta.back"),
        actions: closeActions,
        nonActions: [...(onClose ? [onClose] : [])],
      };
      return {
        title,
        subtitle,
        primaryButton,
        secondaryButton,
      };
    }
    if (cfarExerciseError === AirCfarExerciseFailureEnum.ContractExpired) {
      title = i18nContext("cfarExercise.error.contractExpired.title");
      subtitle = [
        <I18nMarkup
          tKey={i18nContext("cfarExercise.error.contractExpired.cfarWindow")}
        />,
        i18nContext(
          "cfarExercise.error.contractExpired.airlineCancellationPolicy"
        ),
        i18nContext("cfarExercise.error.contactSupport"),
      ];
      secondaryButton = {
        text: i18nContext("cfarExercise.error.secondaryButton"),
        actions: closeActions,
        nonActions: [() => generalSupportChat(), ...(onClose ? [onClose] : [])],
      };
      return {
        title,
        subtitle,
        primaryButton,
        secondaryButton,
      };
    }
    if (cfarExerciseError === AirCfarExerciseFailureEnum.Exercised) {
      title = i18nContext("cfarExercise.error.exercised.title");
      subtitle = [
        i18nContext("cfarExercise.error.exercised.alreadyUsed"),
        i18nContext("cfarExercise.error.contactSupport"),
      ];
      primaryButton = {
        text: i18nContext("cfarExercise.cta.close"),
        actions: closeActions,
        nonActions: [...(onClose ? [onClose] : [])],
      };
      secondaryButton = {
        text: i18nContext("cfarExercise.error.secondaryButton"),
        actions: closeActions,
        nonActions: [() => generalSupportChat(), ...(onClose ? [onClose] : [])],
      };
      return {
        title,
        subtitle,
        primaryButton,
        secondaryButton,
      };
    }

    title = i18nContext("cfarExercise.error.somethingWentWrong");
    subtitle = [i18nContext("cfarExercise.error.unableProcessRequest")];
    primaryButton = {
      text: i18nContext("cfarExercise.cta.close"),
      actions: closeActions,
      nonActions: [...(onClose ? [onClose] : [])],
    };
    secondaryButton = {
      text: i18nContext("cfarExercise.error.secondaryButton"),
      actions: closeActions,
      nonActions: [() => generalSupportChat(), ...(onClose ? [onClose] : [])],
    };
    return {
      title,
      subtitle,
      primaryButton,
      secondaryButton,
    };
  } else {
    if (cfarExerciseProgress === CfarExerciseProgress.Completed) {
      title = i18nContext("cfarExercise.complete.title");
      subtitle = [
        i18nContext("cfarExercise.complete.paragraph1"),
        i18nContext("cfarExercise.complete.paragraph2"),
      ];
      primaryButton = {
        text: i18nContext("cfarExercise.complete.cta"),
        actions: closeActions,
        nonActions: [...(onClose ? [onClose] : [])],
      };

      return {
        title,
        subtitle,
        primaryButton,
      };
    }
  }
  return null;
};
