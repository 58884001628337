import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { RawEvent } from "./types";

// Define the enum for tracking attribute names
enum TrackingAttributeName {
  AttributeA = "AttributeA",
  AttributeB = "AttributeB",
}
// Map attribute names to corresponding value types
type AttributeValueMap = {
  [TrackingAttributeName.AttributeA]: number;
  [TrackingAttributeName.AttributeB]: string;
};
// Define the shape of the TrackingAttributes object
type TrackingAttributesState = {
  [key in TrackingAttributeName]?: AttributeValueMap[key];
};

export type TrackingAttributesMapRef =
  MutableRefObject<TrackingAttributesState>;

const TrackingAttributesContext = createContext<
  | {
      setAttribute: <T extends TrackingAttributeName>(
        attributeName: T,
        value: AttributeValueMap[T]
      ) => void;
      setAttributes: (newAttributes: Partial<TrackingAttributesState>) => void;
      attributes: TrackingAttributesMapRef;
      useTrackEvent: () => EventService;
      useTrackOnLoad: EventService;
    }
  | undefined
>(undefined);

export const useEventTracking = () => {
  const context = useContext(TrackingAttributesContext);
  const mockedAttributesRef = useRef<TrackingAttributesState>({});
  if (!context) {
    console.warn(
      `Must be used within a TrackingAttributesProvider. Add a TrackingProvider to your root App component`
    );
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setAttribute: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setAttributes: () => {},
      attributes: mockedAttributesRef,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useTrackEvent: () => () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useTrackOnLoad: () => () => {},
    };
  }
  return context;
};

// replace type with tracked event type
type EventService = (event: RawEvent) => void;

type EventServiceHook = () => {
  trackEvent: EventService;
};
export const TrackingProvider = ({
  children,
  handlers,
}: PropsWithChildren<{
  handlers: EventServiceHook[];
}>) => {
  // Use a ref to store attributes
  const attributes = useRef<TrackingAttributesState>({});
  // Callback to set attributes with type safety
  const setAttribute = useCallback(
    <T extends TrackingAttributeName>(
      attributeName: T,
      value: AttributeValueMap[T]
    ) => {
      attributes.current[attributeName] = value;
    },
    []
  );

  const setAttributes = useCallback(
    (newAttributes: Partial<TrackingAttributesState>) => {
      attributes.current = {
        ...attributes.current,
        ...newAttributes,
      };
    },
    []
  );

  const useTrackEvent = (): EventService => {
    const eventTrackers = handlers.map((handler) => handler());
    return (event: RawEvent) =>
      eventTrackers.map((tracker) => tracker.trackEvent(event));
  };

  const useTrackOnLoad: EventService = (event) => {
    const trackEvent = useTrackEvent();
    useEffect(() => {
      trackEvent(event);
    }, []);
  };

  return (
    <TrackingAttributesContext.Provider
      value={{
        setAttributes,
        setAttribute,
        attributes,
        useTrackEvent,
        useTrackOnLoad,
      }}
    >
      {children}
    </TrackingAttributesContext.Provider>
  );
};
