import {
  IconNameEnum,
  useFintechUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFintechUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./FintechProduct.module.scss";
import clsx from "clsx";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import React, { useMemo } from "react";
import {
  FintechProductBullet,
  FintechProductBulletProps,
  FintechProductInfoLinksSection,
  FintechProductOptionsSection,
} from "@components/core-fintech-ui";

export interface SelectionOfferItemProp {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: JSX.Element;
  selectedContent: FintechProductBulletProps;
}

export enum FintechProductInfoLinksPlacement {
  BelowOffers = "BelowOffers",
  AboveOffers = "AboveOffers",
}

export enum FintechProductInfoLinksLayout {
  Horizontal = "Horizontal",
  Vertical = "Vertical",
}

export interface FintechProductProps {
  title: string;
  iconName: IconNameEnum;
  body: JSX.Element;
  selectionOffers: {
    options: SelectionOfferItemProp[];
  };
  howItWorksDialog: JSX.Element;
  viewTermsLink: string;
  optionsSectionUI?: {
    showTitle?: boolean;
  };
  // To support portals that require different positioning of info links (FintechProductInfoLinksSection)
  infoLinksUI?: {
    placement?: FintechProductInfoLinksPlacement;
    layout?: FintechProductInfoLinksLayout;
    suffixIcon?: { iconName: IconNameEnum; className?: string };
    displayViewTermsAsModal?: boolean;
  };
  radioButtonPosition: "start" | "end";
  showCloseButtonInDialog?: boolean;
}

export const FintechProduct = (props: FintechProductProps) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProduct;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const [showSelectionRow, setShowSelectionRow] = React.useState(false);

  return (
    <div className={clsx(block)}>
      <div
        className={cn("header-container", {
          "show-selection-row": showSelectionRow,
        })}
      >
        <Icon
          className={cn("header-image")}
          useCurrentColor={false}
          iconName={props.iconName}
        />
        <h3 className={cn("header-title")}>{props.title}</h3>
      </div>
      <Body
        {...props}
        cn={cn}
        showSelectionRow={showSelectionRow}
        setShowSelectionRow={setShowSelectionRow}
      />
    </div>
  );
};

const Body = (
  props: FintechProductProps & {
    cn: (className: string) => string;
    showSelectionRow: boolean;
    setShowSelectionRow: (isSeleted: boolean) => void;
  }
) => {
  const { t } = useI18nContext();

  const {
    cn,
    showSelectionRow,
    setShowSelectionRow,
    selectionOffers,
    infoLinksUI = {
      placement: FintechProductInfoLinksPlacement.BelowOffers,
      layout: FintechProductInfoLinksLayout.Horizontal,
    },
    howItWorksDialog,
    viewTermsLink,
    showCloseButtonInDialog,
    radioButtonPosition,
    optionsSectionUI = {
      showTitle: false,
    },
    body,
  } = props;

  const selectedOffer = useMemo(
    () => selectionOffers.options.find((offer) => offer.selected),
    [selectionOffers.options]
  );

  const SelectionOffersAndInfoLinks = () => {
    if (
      infoLinksUI?.placement === FintechProductInfoLinksPlacement.AboveOffers
    ) {
      return (
        <>
          <FintechProductInfoLinksSection
            howItWorksDialog={howItWorksDialog}
            viewTermsLink={viewTermsLink}
            layout={infoLinksUI.layout}
            showCloseButtonInDialog={showCloseButtonInDialog}
            suffixIcon={infoLinksUI.suffixIcon}
          />
          <FintechProductOptionsSection
            selectionOffers={selectionOffers}
            setShowSelectionRow={setShowSelectionRow}
            radioButtonPosition={radioButtonPosition}
          />
        </>
      );
    } else {
      return (
        <>
          <FintechProductOptionsSection
            selectionOffers={selectionOffers}
            showTitle={optionsSectionUI.showTitle}
            setShowSelectionRow={setShowSelectionRow}
            radioButtonPosition={radioButtonPosition}
          />
          <FintechProductInfoLinksSection
            howItWorksDialog={howItWorksDialog}
            viewTermsLink={viewTermsLink}
            layout={infoLinksUI.layout}
            showCloseButtonInDialog={showCloseButtonInDialog}
            suffixIcon={infoLinksUI.suffixIcon}
            displayViewTermsAsModal={infoLinksUI.displayViewTermsAsModal}
          />
        </>
      );
    }
  };

  return selectedOffer != null && showSelectionRow ? (
    <div className={cn("selected-content")}>
      <FintechProductBullet
        {...selectedOffer.selectedContent}
        className={clsx(
          selectedOffer.selectedContent.className,
          cn("selected-bullet")
        )}
      />
      <button
        onClick={() => {
          setShowSelectionRow(false);
        }}
        className={cn("button")}
      >
        {t("editProtection")}
      </button>
    </div>
  ) : (
    <>
      {body}
      <SelectionOffersAndInfoLinks />
    </>
  );
};
