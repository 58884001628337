import React, { createContext, useContext } from "react";
import { PortalConfig } from "../../types";

type ConfigContext = Omit<PortalConfig, "bootConfig">;

export const PortalConfigContext = createContext<ConfigContext | undefined>(
  undefined
);

export const usePortalConfigContext = () => {
  const portalConfig = useContext(PortalConfigContext);
  if (!portalConfig) {
    throw new Error("Portal config is not available");
  }
  return portalConfig;
};

interface Props {
  children: React.ReactNode;
  config: PortalConfig;
}

export function PortalConfigProvider({ children, config }: Props) {
  return (
    <PortalConfigContext.Provider value={config}>
      {children}
    </PortalConfigContext.Provider>
  );
}
