import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Box } from "@material-ui/core";
import { Slot } from "@hopper-b2b/ui";
import {
  ClientName,
  DisruptionProductType,
  ITripTerminus,
  RegionType,
  TAPPED_FILTERS,
  TripDetails,
} from "@hopper-b2b/types";

import {
  getEnvVariables,
  hasHTSConnectSupport,
  useEnableCfar,
  useEnableChfar,
  useEnableCoreDisruptionProtection,
  useEnableFintechSelection,
  useEnableMissedConnection,
  useEnableScheduleChange,
  useEnableWallet,
  useHideReviewItitinerary,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import { CfarOffers } from "../../../cfar/CfarOffers";
import { ChfarOffer } from "../../../chfar/ChOffer";
import {
  DisruptionOffer,
  DisruptionOfferPage,
} from "../../../disruption/components";
import { FlightShopSearchFilter } from "../../../search/components/FlightShopSearchControlV3/components";
import { AppliedFilterTags } from "../../../search/components/FlightShopSearchControlV3/components/AppliedFilterTags";
import { WalletOfferPage } from "../../../wallet";
import { FlightShopHeader } from "../../components";
import { FintechSelectionPage } from "../../components/FintechSelectionPage";
import { FareClassOptionSelection } from "../../components/FlightShopHeader/components/FareClassOptionSelection";
import { FlightShopProgressHeader } from "../../components/FlightShopProgressHeader";
import { FlightShopReviewItinerary } from "../../components/FlightShopReviewItinerary";
import { MobileFareDetails } from "../../components/MobileFareDetails";
import { MobilePricePrediction } from "../../components/MobilePricePrediction";
import { UberFlightMobilePricePrediction } from "../../components/UberFlightMobilePricePrediction";
import { useGoToNextStep } from "../../hooks/flightShopNavigationHooks";
import {
  isInCfarOffersStepSelector,
  isInChfarOfferStepSelector,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  isInFareDetailsStepSelector,
  isInFintechSelectionStepSelector,
  isInMissedConnectionStepSelector,
  isInPredictionStepSelector,
  isInReviewStepSelector,
  isInScheduleChangeStepSelector,
  isInDisruptionProtectionOfferStepSelector,
  isInWalletOfferStepSelector,
  flightShopProgressSelector,
} from "../../reducer";
import { FlightList } from "./FlightList";
import { IFlightListProps } from "./FlightList/component";
import { trackEvent } from "@hopper-b2b/api";

type MobileFlightShopProps = {
  isPriceFreezeEnabled: boolean;
  tripDetails: TripDetails;
  origin: ITripTerminus | null;
} & Omit<IFlightListProps, "isInChooseReturnStep">;

export const MobileFlightShop = ({
  isPriceFreezeEnabled,
  origin,
  flightsToRender,
  tripSummariesLoading,
  flights,
  rewardsKey,
  handleFareSubmit,
  fareClassFilter,
  handleFlightSelect,
  expandedFareDetails,
  departureDate,
  returnDate,
  hasFlightsError,
  selectedTrip,
  maxFlightPrice,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setOpenCalendarModal,
  isRoundTrip,
}: MobileFlightShopProps) => {
  const history = useHistory();

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [renderReturnLoader, setRenderReturnLoader] = useState(false);

  const hideReviewItinerary = useHideReviewItitinerary();
  const enableFintechSelection = useEnableFintechSelection();

  const clientContext = useContext(ClientContext);
  const isHTSConnect = hasHTSConnectSupport();

  const goToNextStep = useGoToNextStep();

  const isSessionAuthenticated = useIsSessionAuthenticated();

  const showFlightScheduleChange = useEnableScheduleChange();
  const showFlightMissedConnection = useEnableMissedConnection();
  const showFlightDisruptionProtection = useEnableCoreDisruptionProtection();
  const showFlightCfar = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const showFlightWallet = useEnableWallet();

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInChooseReturnStep = useSelector(isInChooseReturnStepSelector);
  const isInReviewStep = useSelector(isInReviewStepSelector);
  const isInPredictionStep = useSelector(isInPredictionStepSelector);
  const isInFareDetailsStep = useSelector(isInFareDetailsStepSelector);
  const isInFintechSelectionStep = useSelector(
    isInFintechSelectionStepSelector
  );
  const isInScheduleChangeStep = useSelector(isInScheduleChangeStepSelector);
  const isInMissedConnectionStep = useSelector(
    isInMissedConnectionStepSelector
  );
  const isInDisruptionProtectionOfferStep = useSelector(
    isInDisruptionProtectionOfferStepSelector
  );
  const isInCfarOffersStep = useSelector(isInCfarOffersStepSelector);
  const isInChfarOfferStep = useSelector(isInChfarOfferStepSelector);
  const isInWalletOfferStep = useSelector(isInWalletOfferStepSelector);

  const headerClassName = useMemo(() => {
    if (isInPredictionStep) return "mobile-prediction-header";
    if (isInFareDetailsStep) return "fare-details";
    if (isInReviewStep) return "mobile-review-itinerary-header";
    if (isInFintechSelectionStep) return "mobile-fintech-selection-header";
    if (isInScheduleChangeStep || isInMissedConnectionStep)
      return "mobile-disruption-header";
    if (isInCfarOffersStep) return "mobile-cfar-header";
    if (isInChfarOfferStep) return "mobile-chfar-header";
    if (isInDisruptionProtectionOfferStep)
      return "mobile-disruption-protection-header";

    return "mobile-shop-header";
  }, [
    isInPredictionStep,
    isInFareDetailsStep,
    isInReviewStep,
    isInFintechSelectionStep,
    isInScheduleChangeStep,
    isInMissedConnectionStep,
    isInDisruptionProtectionOfferStep,
    isInCfarOffersStep,
    isInChfarOfferStep,
  ]);

  const isTripEditHidden = useMemo(
    () =>
      getEnvVariables("clientName") === ClientName.NUBANK ||
      (getEnvVariables("clientName") === ClientName.UBER
        ? !(isInChooseDepartureStep || isInChooseReturnStep)
        : isPriceFreezeEnabled ||
          !(isInChooseDepartureStep || isInChooseReturnStep)),
    [isPriceFreezeEnabled, isInChooseDepartureStep, isInChooseReturnStep]
  );

  const isFilterHidden = useMemo(
    () =>
      getEnvVariables("clientName") === ClientName.UBER
        ? !isInChooseDepartureStep
        : isPriceFreezeEnabled || !isInChooseDepartureStep,
    [isPriceFreezeEnabled, isInChooseDepartureStep]
  );

  const handleGoToNextStep = useCallback(
    (replace?: boolean) => {
      goToNextStep(replace);
    },
    [goToNextStep]
  );

  const showWalletStep =
    showFlightWallet && isSessionAuthenticated && isInWalletOfferStep;

  const handleOpenFilters = useCallback(() => {
    setFiltersOpen(true);
    trackEvent({ eventName: TAPPED_FILTERS, properties: undefined });
  }, [setFiltersOpen]);

  const resetRenderReturnLoader = () => setRenderReturnLoader(false);

  useEffect(() => {
    if (
      isInChooseReturnStep &&
      clientContext?.slots?.includes("flight-shop-transition-loader") &&
      !selectedTrip?.returnFareId
    ) {
      setRenderReturnLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInChooseReturnStep]);

  return renderReturnLoader ? (
    <Slot
      id="flight-shop-transition-loader"
      resetRenderLoader={resetRenderReturnLoader}
    />
  ) : (
    <>
      <Slot
        id="flight-shop-mobile-header"
        onFiltersClick={handleOpenFilters}
        className={headerClassName}
        isFilterHidden={isFilterHidden}
        isTripEditHidden={isTripEditHidden}
        assets={clientContext?.assets || undefined}
        component={
          <Box className="progress-header-menu-wrapper">
            <FlightShopProgressHeader
              onFiltersClick={handleOpenFilters}
              className={headerClassName}
              isFilterHidden={isFilterHidden}
              isTripEditHidden={isTripEditHidden}
              assets={clientContext?.assets || undefined}
            />
          </Box>
        }
      />

      {isInChooseDepartureStep || isInChooseReturnStep ? (
        <>
          <Box className="mobile-flight-shop-search-filters-and-sort-section">
            <FlightShopSearchFilter
              hideAirportFilter={
                isHTSConnect ||
                origin?.id.code.regionType === RegionType.Airport
              }
              filtersOpen={filtersOpen}
              setFiltersOpen={setFiltersOpen}
              isReturn={isInChooseReturnStep}
            />
            {/* <SortOptionSelection /> */}
          </Box>
          <Box className={"mobile-filter-reset-chips"}>
            <AppliedFilterTags />
          </Box>
          <FareClassOptionSelection />
          {isInChooseDepartureStep && flightsToRender?.length > 0 ? (
            <Box className="flight-shop-price-prediction-container">
              <MobilePricePrediction
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
                history={history}
              />
            </Box>
          ) : null}

          {flights ? (
            <FlightShopHeader
              isMobile={true}
              onFiltersClick={handleOpenFilters}
            />
          ) : null}

          <FlightList
            flightsToRender={flightsToRender}
            tripSummariesLoading={tripSummariesLoading}
            flights={flights}
            rewardsKey={rewardsKey}
            handleFareSubmit={handleFareSubmit}
            fareClassFilter={fareClassFilter}
            handleFlightSelect={handleFlightSelect}
            expandedFareDetails={expandedFareDetails}
            departureDate={departureDate}
            returnDate={returnDate}
            isInChooseReturnStep={isInChooseReturnStep}
            hasFlightsError={hasFlightsError}
            selectedTrip={selectedTrip}
            maxFlightPrice={maxFlightPrice}
            hasAppliedFareClassFilter={hasAppliedFareClassFilter}
            hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
            setOpenCalendarModal={setOpenCalendarModal}
            isRoundTrip={isRoundTrip}
            disablePriceFreeze={!isPriceFreezeEnabled}
          />
        </>
      ) : null}
      {isInPredictionStep ? (
        <UberFlightMobilePricePrediction onClose={() => history.goBack()} />
      ) : null}
      {!hideReviewItinerary && isInReviewStep ? (
        <FlightShopReviewItinerary isMobile />
      ) : null}
      {enableFintechSelection && isInFintechSelectionStep ? (
        <FintechSelectionPage onContinue={handleGoToNextStep} />
      ) : null}
      {isInFareDetailsStep ? <MobileFareDetails /> : null}
      {showFlightCfar && isInCfarOffersStep ? (
        <CfarOffers goToNextStep={handleGoToNextStep} />
      ) : null}
      {showFlightChfar && isInChfarOfferStep ? (
        <ChfarOffer goToNextStep={handleGoToNextStep} />
      ) : null}
      {showWalletStep ? (
        <WalletOfferPage goToNextStep={handleGoToNextStep} />
      ) : null}
      {showFlightScheduleChange && isInScheduleChangeStep ? (
        <DisruptionOffer
          type={DisruptionProductType.ScheduleChange}
          goToNextStep={handleGoToNextStep}
        />
      ) : null}
      {showFlightMissedConnection && isInMissedConnectionStep ? (
        <DisruptionOffer
          type={DisruptionProductType.MissedConnection}
          goToNextStep={handleGoToNextStep}
        />
      ) : null}
      {showFlightDisruptionProtection && isInDisruptionProtectionOfferStep ? (
        <DisruptionOfferPage goToNextStep={handleGoToNextStep} />
      ) : null}
    </>
  );
};
