import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./HowItWorksDialog.module.scss";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  Dialog,
  DialogTrigger,
  Icon,
  IframeDialogContent,
} from "@b2bportal/core-ui";

const TRANS_PATH = "core-disruption.howItWorksDialog";

export interface HowItWorksDialogProps {
  minDelayHoursThreshold: number;
  maxAmountRefunded: string;
  maxAmountCovered: string;
  viewTermsLink: string;
  benefitsFromAirlineLink?: string;
  bulletLinePrefixIconName?: IconNameEnum;
  displayExternalLinksAsModal?: boolean;
}

export const HowItWorksDialog = ({
  minDelayHoursThreshold,
  maxAmountCovered,
  viewTermsLink,
  benefitsFromAirlineLink,
  bulletLinePrefixIconName,
  displayExternalLinksAsModal,
}: HowItWorksDialogProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.HowItWorksDialog;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const productName = t("core-disruption.productName");
  const portalName = t("core-disruption.howItWorksDialog.portalName");

  const MainBulletsSection = () => {
    return (
      <>
        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey={`${TRANS_PATH}.bullets.one`}
              values={{ portalName, maxAmountCovered }}
              components={[<span className={cn("bullet-one-rebook")} />]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 1,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey={`${TRANS_PATH}.bullets.two`}
              values={{ portalName, maxAmountCovered }}
              components={[<span className={cn("bullet-two-refund")} />]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 2,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={<span>{t(`${TRANS_PATH}.bullets.three`)}</span>}
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 3,
            className: cn("bullet-icon"),
          }}
          iconBullet={
            bulletLinePrefixIconName != null
              ? {
                  iconName: bulletLinePrefixIconName,
                  className: cn("bullet-icon"),
                }
              : undefined
          }
        />
      </>
    );
  };

  const ImportantInformationBulletsSection = ({
    displayExternalLinksAsModal,
  }) => {
    return (
      <>
        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <Trans
              i18nKey={`${TRANS_PATH}.importantInformation.one`}
              components={[
                <span className={cn("important-information-one-bold-text")} />,
              ]}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 1,
            className: cn("bullet-icon"),
          }}
          iconBullet={{
            iconName: IconNameEnum.disruptionHowItWorksDialogInfoLineOne,
            className: cn("important-information-line-one-icon"),
          }}
        />

        <BulletItem
          className={cn("bullet-container")}
          textChildren={
            <TextWithExternalLink
              transI18nKey={`${TRANS_PATH}.importantInformation.two`}
              transValues={{}}
              transComponents={[]}
              linkUrl={benefitsFromAirlineLink ?? ""}
              linkClassName={cn("benefits-from-airline-link")}
              displayInModal={displayExternalLinksAsModal}
            />
          }
          textClassName={cn("bullet-text")}
          numberBullet={{
            num: 2,
            className: cn("bullet-icon"),
          }}
          iconBullet={{
            iconName: IconNameEnum.disruptionHowItWorksDialogInfoLineTwo,
            className: cn("important-information-line-two-icon"),
          }}
        />
      </>
    );
  };

  return (
    <div className={block}>
      <Icon
        iconName={IconNameEnum.disruptionProtection}
        className={cn("header-icon")}
        useCurrentColor={false}
      />

      <h2 className={cn("dialog-title")}>{t(`${TRANS_PATH}.title`)}</h2>

      <span className={cn("bullets-title")}>
        <Trans
          i18nKey={`${TRANS_PATH}.subtitle`}
          values={{ minDelayHoursThreshold }}
          components={[<span className={cn("dialog-subtitle")} />]}
        />
      </span>
      <MainBulletsSection></MainBulletsSection>

      <span className={cn("important-information-title")}>
        {t(`${TRANS_PATH}.importantInformation.title`)}
      </span>
      <ImportantInformationBulletsSection
        displayExternalLinksAsModal={displayExternalLinksAsModal}
      ></ImportantInformationBulletsSection>

      <span className={cn("footer-text", { first: true })}>
        <TextWithExternalLink
          transI18nKey={`${TRANS_PATH}.footer.one`}
          transValues={{ productName }}
          transComponents={[
            <span className={cn("footer-text-one-bold-text")} />,
          ]}
          linkUrl={"https://hts.hopper.com/legal/what-are-hts-add-ons"}
          linkClassName={cn("see-here-link")}
          displayInModal={displayExternalLinksAsModal}
        />
      </span>

      <span className={cn("footer-text", { last: true })}>
        <TextWithExternalLink
          transI18nKey={`${TRANS_PATH}.footer.two`}
          transValues={{}}
          transComponents={[]}
          linkUrl={viewTermsLink}
          linkClassName={cn("terms-conditions-link")}
          displayInModal={displayExternalLinksAsModal}
        />
      </span>
    </div>
  );
};

type BulletItemProps = {
  className;
  textChildren: React.ReactNode;
  textClassName: string;
  numberBullet: {
    num: number;
    className: string;
  };
  iconBullet?: {
    iconName: IconNameEnum;
    className?: string;
  };
};

const BulletItem = ({
  className,
  textChildren,
  textClassName,
  ...props
}: BulletItemProps) => {
  return (
    <div className={className}>
      {props.iconBullet != null ? (
        <Icon {...props.iconBullet} useCurrentColor={false} />
      ) : (
        <span className={props.numberBullet.className}>
          {props.numberBullet.num}
        </span>
      )}
      <span className={textClassName}>{textChildren}</span>
    </div>
  );
};

type TextWithExternalLinkProps = {
  transI18nKey: string;
  transValues: { [key: string]: string };
  transComponents: JSX.Element[];
  linkUrl: string;
  linkClassName: string;
  displayInModal?: boolean;
};

const TextWithExternalLink = ({
  transI18nKey,
  transValues,
  transComponents,
  linkUrl,
  linkClassName,
  displayInModal,
}: TextWithExternalLinkProps) => {
  return (
    <Dialog>
      <Trans
        i18nKey={transI18nKey}
        values={transValues}
        components={[
          ...transComponents,
          displayInModal ? (
            <DialogTrigger className={linkClassName}></DialogTrigger>
          ) : (
            <a href={linkUrl} target="_blank" className={linkClassName} />
          ),
        ]}
      />
      <IframeDialogContent showHeaderBottomBorder={false}>
        <iframe src={linkUrl} />
      </IframeDialogContent>
    </Dialog>
  );
};
