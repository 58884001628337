import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarExerciseProgress,
  ChfarExerciseProgress,
  ClientName,
  FlightItineraryState,
  HotelItineraryState,
  IApiConfig,
  MyTripsFilter,
  VIEWED_MY_TRIPS,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  IconComponent,
  IconName,
  LoadingPopup,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getHopperLocatorFromBookedFlightItinerary,
  updateApiConfigAction,
  useEnableLodging,
  useEnablePriceFreeze,
  useEnablePriceWatch,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { tripsApiConfigStoreKey } from "../../reducers";
import {
  setCfarExerciseProgress,
  setChfarExerciseProgress,
  setSelectedFlight,
  setTripsFilter,
} from "../TripsList/actions/actions";
import { ItinerariesModal } from "../TripsList/components/ItineraryList/components/ItinerariesModal";
import {
  getCancellationCompleted,
  getCfarExerciseProgress,
  getChfarExerciseProgress,
} from "../TripsList/reducer/selectors";
import { parseQueryStringFromString } from "../TripsList/sagas/populateTripQueryParamsSaga";
import { CfarExercise } from "../../modules/ancillaries/cfar/CfarExercise";
import { CfarExercise as UberCfarExercise } from "./components/CfarExercise";
import { ChfarExercise } from "./components/ChfarExercise";
import { ItineraryList } from "./components/ItineraryList";
import { MobileFlightItineraryDetails } from "./components/ItineraryList/components/MobileFlightItineraryDetails";
import { MobileFilterMenu } from "./components/MobileFilterMenu";
import { NoTripResults } from "./components/NoResults";
import { PriceFreezeList } from "./components/PriceFreezeList";
import { WatchDetailsModalContent } from "./components/WatchDetailsModalContent";
import { TripsListConnectorProps } from "./container";
import "./styles.scss";
import { ClientContext } from "../../App";
import { BASE_PATH_HOME } from "../../utils/paths";
import { ChatPropertiesType } from "@b2bportal/chat-api";
import { MobileHotelItineraryDetails } from "./components/ItineraryList/components/MobileHotelItineraryDetails";
import {
  DisruptionExerciseProgress,
  getDisruptionExerciseProgress,
  DisruptionUniversalExercise,
  setDisruptionExerciseProgress,
} from "@b2bportal/core-disruption";
import { PATH_TRIPS } from "@hopper-b2b/common-utils";

export interface ITripsListProps extends TripsListConnectorProps {
  isMobile?: boolean;
  apiConfig: IApiConfig;
  onSupportClick: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

export const TripsList = ({
  fetchFlights,
  fetchHotels,
  // fetchCars,
  isMobile,
  selectedFlight,
  selectedHotel,
  selectedCar,
  selectedWatch,
  tripsFilter,
  tripsLoading,
  hasError,
  hasTripsToDisplay,
  openModal,
  listPriceFreeze,
  apiConfig,
  listWatches,
  setSelectedWatch,
  setOpenModal,
  onSupportClick,
}: ITripsListProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const clientName = getEnvVariables("clientName");
  const isUber = clientName === ClientName.UBER;
  const isNubank = clientName === ClientName.NUBANK;
  const clientContext = useContext(ClientContext);

  const cfarExerciseProgress = useSelector(getCfarExerciseProgress);
  const chfarExerciseProgress = useSelector(getChfarExerciseProgress);
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const cancellationCompleted = useSelector(getCancellationCompleted);

  const showFlightPriceFreeze = useEnablePriceFreeze();
  const showFlightPriceWatch = useEnablePriceWatch();
  const showHotels = useEnableLodging();
  const handleTripsRouting = useCallback(
    (selectedTrip: BookedFlightItineraryWithDepartureTime = null) => {
      dispatch(setSelectedFlight(selectedTrip));
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
      );
      dispatch(setCfarExerciseProgress(CfarExerciseProgress.NotStarted));
      if (selectedWatch) {
        setSelectedWatch(null);
        setOpenModal({ type: null, selectedItinerary: null });
        listWatches(history);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    [
      dispatch,
      history,
      listWatches,
      selectedWatch,
      setOpenModal,
      setSelectedWatch,
    ]
  );

  const queryString = useMemo(
    () => parseQueryStringFromString(search),
    [search]
  );

  const handleCfarProgress = useCallback(() => {
    if (cfarExerciseProgress === CfarExerciseProgress.Completed) {
      // Fire a message to redirect to the Uber flight search page when landing here with back button after completing a CFAR exercise
      const agentLocator =
        getHopperLocatorFromBookedFlightItinerary(selectedFlight);
      window.parent.postMessage(
        {
          type: "BOOKING_CANCELED",
          payload: { agentLocator },
        },
        import.meta.env["VITE_UBER_URL"]
      );
      dispatch(setCfarExerciseProgress(CfarExerciseProgress.NotStarted));
      dispatch(setSelectedFlight(null));
    } else {
      dispatch(setCfarExerciseProgress(CfarExerciseProgress.NotStarted));
    }
  }, [dispatch, cfarExerciseProgress, selectedFlight]);

  const handleChfarProgress = useCallback(
    (progress: ChfarExerciseProgress) => {
      dispatch(setChfarExerciseProgress(progress));
    },
    [dispatch]
  );

  const handleDisruptionProgress = useCallback(
    (progress: DisruptionExerciseProgress) => {
      dispatch(setDisruptionExerciseProgress(progress));
    },
    [dispatch]
  );

  // Fire a message to redirect to the Uber flight search page when landing here with back button after completing a self-serve cancellation
  useEffect(() => {
    if (cancellationCompleted && selectedFlight === null) {
      window.parent.postMessage(
        {
          type: "FINISH_SESSION",
          payload: null,
        },
        import.meta.env["VITE_UBER_URL"]
      );
    }
  }, [dispatch, cancellationCompleted, selectedFlight]);

  useEffect(() => {
    dispatch(updateApiConfigAction(tripsApiConfigStoreKey, apiConfig));
  }, [apiConfig, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      eventName: VIEWED_MY_TRIPS,
      properties: {},
    });
  }, []);

  useEffect(() => {
    if (queryString.tripsFilter !== tripsFilter) {
      dispatch(
        setTripsFilter(
          (queryString.tripsFilter as MyTripsFilter) ??
            MyTripsFilter.UPCOMING_TRIPS
        )
      );
    }
  }, [dispatch, queryString, tripsFilter]);

  useEffect(() => {
    if (!queryString.tripId || queryString.tripId === "") {
      handleTripsRouting();
    } else {
      if (
        !queryString.cfarExerciseProgress ||
        queryString.cfarExerciseProgress === "0"
      ) {
        handleCfarProgress();
      }
      if (
        !queryString.chfarExerciseProgress ||
        queryString.chfarExerciseProgress === "0"
      ) {
        handleChfarProgress(ChfarExerciseProgress.NotStarted);
      }
      if (
        !queryString.disruptionExerciseProgress ||
        queryString.disruptionExerciseProgress === "0"
      ) {
        handleDisruptionProgress(DisruptionExerciseProgress.NotStarted);
      }
    }
  }, [
    handleCfarProgress,
    handleChfarProgress,
    handleDisruptionProgress,
    handleTripsRouting,
    queryString,
    selectedWatch?.id,
  ]);

  const fetchAllItineraries = useCallback(() => {
    fetchFlights(
      {
        states: [
          FlightItineraryState.Canceled,
          FlightItineraryState.Future,
          FlightItineraryState.Present,
          FlightItineraryState.Past,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );
    // TODO: Check why this throws off the formatting
    showHotels &&
      fetchHotels(
        {
          states: [
            HotelItineraryState.Canceled,
            HotelItineraryState.Future,
            HotelItineraryState.Present,
            HotelItineraryState.Past,
          ],
          referenceDateTime: dayjs().format(),
        },
        history
      );
  }, [fetchFlights, fetchHotels, history, showHotels]);

  useEffect(() => {
    fetchAllItineraries();
    if (showFlightPriceFreeze) listPriceFreeze();
    if (showFlightPriceWatch) listWatches(history);
  }, [
    history,
    fetchAllItineraries,
    listPriceFreeze,
    listWatches,
    showFlightPriceFreeze,
    showFlightPriceWatch,
  ]);

  const filteredListComponent = useMemo(() => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
      case MyTripsFilter.PAST_TRIPS:
        return (
          <>
            <PriceFreezeList />
            <ItineraryList isMobile={isMobile} />
          </>
        );

      case MyTripsFilter.PRIZE_FREEZES:
        return <PriceFreezeList />;
      case MyTripsFilter.WATCHED_TRIPS:
        return <ItineraryList isMobile={isMobile} />;
      default:
        return null;
    }
  }, [isMobile, tripsFilter]);

  const goBack = useCallback(() => {
    history.push(BASE_PATH_HOME);
  }, [history]);

  const selectedItinerary = useMemo(() => {
    const renderCfarExercise = !(
      cfarExerciseProgress === CfarExerciseProgress.NotStarted
    );

    const renderChfarExercise = !(
      chfarExerciseProgress === ChfarExerciseProgress.NotStarted
    );

    const renderDisruptionExercise =
      !(disruptionExerciseProgress === DisruptionExerciseProgress.NotStarted) &&
      !(disruptionExerciseProgress === undefined);

    return (
      <>
        {selectedFlight ? (
          renderCfarExercise ? (
            isUber ? (
              <UberCfarExercise flight={selectedFlight} />
            ) : (
              <CfarExercise flight={selectedFlight} />
            )
          ) : renderChfarExercise ? (
            <ChfarExercise flight={selectedFlight} />
          ) : renderDisruptionExercise ? (
            <DisruptionUniversalExercise
              disruptedFlight={selectedFlight}
              onContactSupport={() => {
                onSupportClick(
                  selectedFlight.bookedItinerary.id,
                  ChatPropertiesType.Air,
                  "General"
                );
              }}
              handleExerciseCompleted={() => {
                setOpenModal({ type: null, selectedItinerary: null });
                // reload the trips and navigate to selected itinerray
                window.location.href = `/${PATH_TRIPS}/?tripId=${selectedFlight.bookedItinerary.id}`;
              }}
            />
          ) : (
            <MobileFlightItineraryDetails onSupportClick={onSupportClick} />
          )
        ) : null}
        {selectedWatch ? <WatchDetailsModalContent /> : null}
        {selectedHotel ? (
          <MobileHotelItineraryDetails onSupportClick={onSupportClick} />
        ) : null}
        <ItinerariesModal isMobile={isMobile} onSupportClick={onSupportClick} />
      </>
    );
  }, [
    cfarExerciseProgress,
    chfarExerciseProgress,
    disruptionExerciseProgress,
    isMobile,
    onSupportClick,
    selectedFlight,
    selectedHotel,
    selectedWatch,
    goBack,
    isUber,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile &&
      (selectedFlight || selectedHotel || selectedCar || selectedWatch) ? (
        selectedItinerary
      ) : (
        <Box
          className={clsx(
            { mobile: isMobile },
            "trips-list",
            "new",
            getEnvVariables("clientName")
          )}
        >
          {isNubank ? (
            <div className="trips-list-header">
              <div className="trips-list-header-button" onClick={goBack}>
                {clientContext?.assets?.back ? (
                  <img src={clientContext.assets.back} className="back-arrow" />
                ) : (
                  <IconComponent
                    ariaLabel="back icon"
                    className="back-arrow"
                    name={isNubank ? IconName.Chevron : IconName.ArrowRight}
                  />
                )}
              </div>
              <div className="trips-list-header-title">{t("myTrips")}</div>
            </div>
          ) : null}
          <MobileFilterMenu />
          <div
            id={`${tripsFilter}-tab`}
            role="tabpanel"
            aria-labelledby={tripsFilter}
          >
            {tripsLoading && (
              <Slot
                id="trips-list-loading-screen"
                open={tripsLoading}
                message={t("fetchingTrips")}
                className={[
                  "trips-loading-modal",
                  ...(isMobile ? ["mobile"] : []),
                ]}
                component={
                  <LoadingPopup
                    open={tripsLoading}
                    indicator={B2BSpinner}
                    message={t("fetchingTrips")}
                    classes={[
                      "trips-loading-modal",
                      ...(isMobile ? ["mobile"] : []),
                    ]}
                  />
                }
              />
            )}
            {!tripsLoading ? <NoTripResults isMobile={isMobile} /> : null}
            {!hasError &&
              (hasTripsToDisplay || openModal) &&
              filteredListComponent}
          </div>
        </Box>
      )}
    </>
  );
};
