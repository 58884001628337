import { useI18nContext } from "@hopper-b2b/i18n";
import { useSelector } from "react-redux";
import { getDisruptionExerciseProgress } from "../../features/exercise";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { useState } from "react";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./styles.module.scss";
import { DisruptionExerciseProgress } from "../../features/exercise";
import { PaymentOpaqueValue } from "@b2bportal/air-disruption-api";
import {
  DisruptionExerciseLandingPage,
  DisruptionExerciseRefundReviewPage,
  DisruptionExerciseRefundConfirmationPage,
} from "@components/disruption";
import DisruptionContactSupport from "./components/DisruptionContactSupport/component";
import DisruptionExerciseNotEligiblePage from "./pages/DisruptionExerciseNotEligiblePage/DisruptionExerciseNotEligiblePage";

export interface DisruptionUniversalExerciseProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  onContactSupport?: () => void;
  handleExerciseCompleted?: () => void;
}

export const DisruptionUniversalExercise = (
  props: DisruptionUniversalExerciseProps
) => {
  const { t, brand } = useI18nContext();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionUniversalExercise;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const [reimbursementTotalAmount, setReimbursementTotalAmount] =
    useState<FiatPrice | null>(null);
  const [refundFulfillmentToken, setRefundFulfillmentToken] = useState<
    string | null
  >(null);
  const [refundBreakdown, setRefundBreakdown] =
    useState<Array<PaymentOpaqueValue> | null>(null);
  const contractId =
    props.disruptedFlight?.ancillaries?.disruptionProtection?.id;
  const email = props.disruptedFlight?.emailAddress;
  const policyDetails =
    props.disruptedFlight?.ancillaries?.disruptionProtection?.policyDetails;
  const openCustomerSupport = props.onContactSupport
    ? props.onContactSupport
    : () => {
        setOpenSupport(true);
      };
  const termsAndConditions =
    brand.urls.disruptionTerms ??
    "https://www.hopper.com/legal/Premium-Disruption-Assistance";

  if (contractId === undefined || policyDetails === undefined) {
    setOpenSupport(true);
    return (
      <DisruptionContactSupport
        open={openSupport}
        onClose={() => {
          setOpenSupport(false);
        }}
      />
    );
  } else {
    return (
      <div className={cn("disruption-universal-exercise")}>
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.NotEligible && (
          <DisruptionExerciseNotEligiblePage
            disruptionProductName={t("core-disruption.productName")}
            disruptionProductLogo={IconNameEnum.disruptionProtection}
            policyDetails={policyDetails}
            onContactSupport={openCustomerSupport}
            termsLink={termsAndConditions}
          />
        )}
        {(disruptionExerciseProgress ===
          DisruptionExerciseProgress.LandingPage ||
          disruptionExerciseProgress ===
            DisruptionExerciseProgress.RebookSelected ||
          disruptionExerciseProgress ===
            DisruptionExerciseProgress.RefundSelected) && (
          <DisruptionExerciseLandingPage
            disruptionProductName={t("core-disruption.productName")}
            disruptionProductLogo={IconNameEnum.disruptionProtection}
            disruptedFlight={props.disruptedFlight}
            contractId={contractId}
            setReimbursementTotalAmount={setReimbursementTotalAmount}
            setRefundBreakdown={setRefundBreakdown}
            setRefundFulfillmentToken={setRefundFulfillmentToken}
            policyDetails={policyDetails}
            onContactSupport={openCustomerSupport}
            termsLink={termsAndConditions}
          />
        )}

        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RefundReview &&
          refundFulfillmentToken !== null &&
          reimbursementTotalAmount != null && (
            <DisruptionExerciseRefundReviewPage
              disruptionProductName={t("core-disruption.productName")}
              disruptionProductLogo={IconNameEnum.disruptionProtection}
              disruptedFlight={props.disruptedFlight}
              contractId={contractId}
              reimbursementTotalAmount={reimbursementTotalAmount}
              refundFulfillmentToken={refundFulfillmentToken}
              refundBreakdown={refundBreakdown}
              onContactSupport={openCustomerSupport}
              termsLink={termsAndConditions}
              policyDetails={policyDetails}
            />
          )}

        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RefundConfirmation &&
          email !== undefined && (
            <DisruptionExerciseRefundConfirmationPage
              email={email}
              productName={t("core-disruption.productName")}
              handleExerciseCompleted={props.handleExerciseCompleted}
            />
          )}

        <DisruptionContactSupport
          open={openSupport}
          onClose={() => {
            setOpenSupport(false);
          }}
        />
      </div>
    );
  }
};
