import {
  LodgingSelectors,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import "./styles.scss";
import { getRoomPricing } from "../../../modules/lodging-checkout/selectors";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { Typography } from "@material-ui/core";

import { ReactComponent as ArrowRight } from "../../../assets/client/arrow-right.svg";
import clsx from "clsx";

export interface ICheckoutCtaSectionProps {
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
  priceLoading?: boolean;
}

const HotelCheckoutCtaSection = ({
  onClick,
  buttonDisabled = false,
  priceLoading = false,
}: ICheckoutCtaSectionProps) => {
  const { t } = useI18nContext();
  const { roomPriceTotalWithTaxesAndFees } = useSelector(getRoomPricing);
  const selectedNights = useSelector(LodgingSelectors.getTotalNights);

  const isOfferApplied = useSelector(WalletSelectors.getIsWalletOfferApplied);
  const hotelPricingBreakdown = useSelector(
    LodgingSelectors.getQuotedLodgingBreakdown
  );

  return (
    <div className="fare-details-bottom-wrapper">
      <div className="fare-details-price-summary-wrapper">
        <div className="fare-details-summary-section">
          <div className="description-text">
            {t("totalPricingPerNight", { count: selectedNights })}
          </div>
          <div className="price-text">
            <Typography
              className={clsx("content", priceLoading ? "loading" : null)}
              variant="body1"
            >
              <DisplayedPrice
                discountedCostFiat={null}
                totalCostFiat={
                  isOfferApplied
                    ? hotelPricingBreakdown.balance.fiat
                    : roomPriceTotalWithTaxesAndFees
                }
              />
            </Typography>
            <div className="installments-badge">
              <p>{t("upToInterestFreeInstallments", { count: 8 })}</p>
            </div>
          </div>
        </div>
        <ButtonWrap
          className="fare-details-button-section"
          onClick={buttonDisabled ? null : onClick}
        >
          <ArrowRight className={buttonDisabled ? "disabled" : ""} />
        </ButtonWrap>
      </div>
    </div>
  );
};

export default HotelCheckoutCtaSection;
